import { ref } from 'vue'
import axios from 'axios';

const imageViewerVisible = ref(false);
const currentImageUrl = ref('');

const showImageViewer = (url) => {
  currentImageUrl.value = url;
  imageViewerVisible.value = true;
};

const closeImageViewer = () => {
  imageViewerVisible.value = false;
  currentImageUrl.value = '';
  console.log("closeImageViewer",imageViewerVisible)
};

const downloadImage = async (imageUrl) => {
  try {
    console.log("props.imageUrl", imageUrl)
    const response = await axios.get(imageUrl, {
      responseType: 'blob', // 以二进制格式下载图片
    });

    // 创建一个链接元素
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'image.png'); // 设置下载文件的名称
    document.body.appendChild(link);
    link.click();

    // 移除链接元素
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('下载图片失败:', error);
  }
};

export { showImageViewer, closeImageViewer, imageViewerVisible, currentImageUrl,downloadImage };