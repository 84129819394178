<template>
  <div class="container">
    <LayerOut>
    </LayerOut>
    <div style="color: aliceblue;padding-left: 5vw;padding-right: 5vw;">
      <h3>
        Product Updates
      </h3>
      <p>
        Keep abreast of all the latest features, enhancements, and improvements we've introduced to elevate your Pook
        experience to new heights.
      </p>
    </div>
    <div class="grid">
      <a v-for="(item, index) in paginatedItems" :key="index" class="grid-item" :href="item.taeget_url" target="_blank"
        rel="noopener noreferrer">
        <div>
          <img :src="item.image" :alt="item.summary" class="grid-image" />
          <h5 class="grid-title">{{ item.title }}</h5>
          <p class="grid-summary">{{ item.summary }}</p>
        </div>
      </a>
    </div>
    <Pagination :totalItems="items.length" :itemsPerPage="itemsPerPage" @pageChanged="handlePageChange" />
    <FooterPage>
    </FooterPage>
  </div>

</template>

<script setup>
import { ref, computed } from 'vue';
import LayerOut from '@/components/LayerOut.vue'
import Pagination from '@/components/Pagination.vue';
import FooterPage from '@/components/footerPage.vue';

const itemsPerPage = ref(5);
const currentPage = ref(1);

const paginatedItems = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return items.value.slice(start, end);
});

const handlePageChange = (page) => {
  currentPage.value = page;
};



const items = ref([
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 1',
    summary: 'Summary for item 1',
    taeget_url: `${window.location.origin}/how-to-face-swap.html`
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 2',
    summary: 'Summary for item 2',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 3',
    summary: 'Summary for item 3',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 4',
    summary: 'Summary for item 4',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 5',
    summary: 'Summary for item 5',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 6',
    summary: 'Summary for item 6',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 7',
    summary: 'Summary for item 7',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 8',
    summary: 'Summary for item 8',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 9',
    summary: 'Summary for item 9',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 10',
    summary: 'Summary for item 5',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 11',
    summary: 'Summary for item 6',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 12',
    summary: 'Summary for item 7',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 13',
    summary: 'Summary for item 8',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
  {
    image: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
    title: 'Title 14',
    summary: 'Summary for item 9',
    taeget_url: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href
  },
]);
</script>

<style scoped>

.container {
  /*路径*/

  left: 0px;
  top: 0px;
  width: auto;
  height: auto;
  background: url("../assets/background.png");
  overflow: hidden;

}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3列 */
  gap: 5vw; /* 格子之间的间距 */
  padding: 2vw 5vw 5vw 5vw;
}

.grid-item {
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none;
}

.grid-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.grid-title {
  margin: 12px 0 8px;
  font-size: 18px;
}

.grid-summary {
  font-size: 14px;
  color: #666;
}
</style>