
<!-- // eslint-disable-next-line vue/multi-word-component-names -->
<script setup>

/*


 第一阶段:
1.样式规整,动效调整 done
2.数据交互部分: 
1) 登陆功能 登陆是否需要呢? 展示先不用? p0 先开发登陆功能 done 
2) 模版切换 done
3) 上传图片 done
4) 多人换脸切换 (多人换脸先不上) # my result 需要开发了 p1 done
5) 适应小屏幕/手机/移动端 p1 done

完善阶段:
1) 积分订阅功能和广告功能 p0 done
		.积分购买,多渠道支持, stripe 设置.
			逻辑:
				1.用户登陆:显示积分
				2.用户未登陆
					自动弹出订阅购买页面-登陆-回到首页,同时显示登陆状态积分-没有订阅再次弹出订阅购买页面-选择不同套餐-发起checkout 请求 -回到首页更新积分套餐
				3.积分不够,弹出购买 p0
		.广告页面,广告支持 p2
2) 多语言支持
3) 登陆注册功能 p0 done
4) 图文重新整理 p0 
5) 服务器/域名/logo p1
6) seo 页面 增加点击切换到主功能区 p2
7) 底部联系方式反馈页链接等
#### z注意注册统一客服-服务相关邮箱,剥离个人身份.


# 优先完善可完善的界面:登陆页,价格页 done
# 登陆 seesion 化,模拟后端验证 #  


第3阶段:

前端需要完善的地方有: 2024-09-04

1.图文整理
2.交互串联,修改一些逻辑上的交互,比如积分不够,弹出购买页面,未登录弹出购买页面
3.语言支持??
4.小尺寸的前端自适应显示,主要涉及到样式的一些更改
5.一些隐私文档以及联系方式
6.待后端完成差不多,域名,服务器,logo 完成
7.数据安全以及可能存在的前端 bug ,多测试.
8.完善积分购买本地和后端的协同处理,一致性. done

以及 CDN 考虑,静态图

前端主要剩余这些问题.


后端服务功能完善: 建表 done
1.登陆字段: id,用户udid,用户邮箱,用户积分,用户ip,用户城市,用户国家,上次登陆时间,更新时间,初始创建时间, 	
  非登陆字段:id,udid,创建日期,用户ip,用户城市,用户国家,
2.积分:id,用户邮箱,udid,总积分,已使用积分,过期时间,创建日期
3.订单:id,udid,stripe id,价格,套餐,积分,创建日期 (非唯一) 
4.模版数据:id,模版名称,模版url,模版分组,创建日期,更新日期
5.换脸任务:id,用户udid,任务id,任务类型,换脸图片,换脸模版,自定义与否,创建日期,更新日期
6.结果存储:id,任务id,换脸结果url,创建日期,更新日期
7.任务状态:id,用户udid,任务id,状态,创建日期,更新日期
8.价格计划字段:id,产品名,产品key,状态,类型,描述,创建日期,更新日期,价格

其他:
9.换脸服务 (另一台机器) 主要交互逻辑 

第四阶段: 
1.支持不同国家语言 p1 
2.图文文本调整 p0 done
3.域名,logo p1
4.部署方式选择
5.服务器
6.细节调整: 1.任务生成,切换下载功能 done
7.小屏幕 logout 调整 done
8.页脚 隐私联系方式等,语言选择开发 p1 done (配置语言待开发)

第五阶段:9月19日

1.ssl https 问题 七牛云配置自定义域名
2.服务器,域购买
3,图文新生成
4.页脚隐私政策
5.config 配置安全性
 */
import { ref, computed, onMounted, watchEffect, onUnmounted } from 'vue';
import LayerOut from '@/components/LayerOut.vue'
import footerPage from '@/components/footerPage.vue'
// import { useStore } from 'vuex'
// import axios from 'axios';
import { useUserStore, activeDivStore } from '@/store/index';
import axiosInstance from '@/axios.config'; // 导入配置好的 Axios 实例
import { fetchImage } from '@/utils/fetchImage'; 
import ImageViewer from './ImageViewer.vue';
import Compressor from 'compressorjs';
import { showImageViewer, closeImageViewer, imageViewerVisible, currentImageUrl, downloadImage } from '@/imageViewerHandler';
const userStore = useUserStore();
const useActiveDivStore = activeDivStore()


const handleImageClick = (event) => {
	const imageUrl = event.target.src;
	showImageViewer(imageUrl);
};

const isLoggedIn = computed(() => userStore.isLoggedIn);
onMounted(() => {
	if (!isLoggedIn.value) {
		userStore.updateNoneLoginUserInfo()
	} else {
		userStore.fetchCredits()
	}
});


const user = computed(() => userStore.isLoggedIn ? userStore.user : userStore.guestUser);
// const guestUser = computed(() => userStore.guestUser);
const userCredits = computed(() => userStore.isLoggedIn ? userStore.userCredit : userStore.guestCredit);
// const showSub = ref(false)
const showlogin = ref(false);
const loading = ref(false);
console.log("userCredits**swapface** ", "userCredits", userCredits.value, "showSubcrib", userStore.showSubcrib, "showlogin",showlogin)



// watchEffect(() => {
// 	if (userStore.showLogin == true) { 
// 		showlogin.value = true
// 		showCreditsModal.value = false
// 	}
// 	if (userStore.showSubcrib == true) {
// 		showSub.value = true
// 		showLoginModal.value = false
// 	}

// 	if (userStore.showSubcrib == false) {
// 		showSub.value = false
// 	}
// });

const fileInput = ref(null);
const templateInput = ref(null);
const contentBox = ref(null)
const index = ref(0)
// const userFace = ref(null)
// const template = ref([])
const isImageUploaded = ref(false);
const activeDiv = ref('swap_face'); 
const user_curent_task_id = ref(null)


// 上传图片
const imageA = ref(null)
const imageB = ref([])
const imageUrlA = ref(null)
const imageUrlB = ref(null)
const show_downLoad = ref(false)
const isUploading = ref(false)
const mylib_result_urls = computed(() => userStore.mylib_result);

// 生成的结果:
const result_url = ref(null)
/*

登录登出逻辑

*/

// const store = useStore()




/**
 * 多个页面显示
 * 
 */
watchEffect(() => {
	console.log(useActiveDivStore.activeDiv)
	if (useActiveDivStore.activeDiv) { 
		activeDiv.value = useActiveDivStore.activeDiv
	}
	if (activeDiv.value === 'my_result' || userStore.mylib_result) {
		console.log("监控数据",userStore.getters.getList)
		mylib_result_urls.value = userStore.getters.getList
	}

})

const toggleDisplay = (div) => {
	useActiveDivStore.updateactiveDiv(div);

	// console.log("glabal ",useActiveDivStore.activeDiv)
	// // 如果切换到 'my_result'，则请求后端获取结果
	// activeDiv.value = useActiveDivStore.activeDiv
	if (div === 'my_result') {
		console.log("glabal ", useActiveDivStore.activeDiv)
		userStore.fetchResultUrls();
		console.log("userStore.mylib_result", userStore.mylib_result)
		// mylib_result_urls.value = userStore.mylib_result
	}
};


// const fetchResultUrls = async () => {
// 	try {

// 		const response = await axiosInstance.post('/api/swap_face/myresult', { "isLogin": isLoggedIn.value, "udid": user.value.udid });
// 		const data = await response.data;
// 		console.log("====",data.result_urls)
// 		mylib_result_urls.value = data.result_urls;
// 	} catch (error) {
// 		console.error('Error fetching results:', error);
// 	}
// };

// 初始化时默认请求一次结果
// onMounted(() => {
// 	if (activeDiv.value === 'my_result') {
// 		fetchResultUrls();
// 	}
// });

/*
返回首页 
*/
const scrollToTop = () => {
	if (contentBox.value) {
		contentBox.value.scrollIntoView({ behavior: 'auto' });
	}
};

/*
模版类型图片列表
*/

const categories = ref([]);
const selectedCategory = ref(null)
const selectedImage = ref(null)


onMounted(async () => {
	try {
		const response = await axiosInstance.get('/api/swap_face/categories_template')
		categories.value = response.data
		if (categories.value.length > 0) {
			selectedCategory.value = { category: categories.value[0], index: 0 }
			selectedImage.value = categories.value[0].images[0]
			imageUrlB.value = selectedImage.value 
		}
	} catch (error) {
		console.error('获取分类数据失败:', error)
		categories.value = [
			{
				'name': 'head shot',
				'images': [
					'https://gd-hbimg.huaban.com/fab6a29b55d14094426bf8c8e32074bb493544cab6a8-pS50M2_fw1200',
				]
			}]
		selectedCategory.value = { category: categories.value[0], index: 0 }
		selectedImage.value = categories.value[0].images[0]
		imageUrlB.value = selectedImage.value 
	}
})


const personSwapItems = ref([
	{
		imageUrl: new URL('../assets/images/swap-face/Celebrity-Face-Swap.webp', import.meta.url).href,
		title: 'Celebrity Face Swap',
		description: 'Experience the excitement of swap your face with character, embrace the joy of becoming your favorite singer, actor, Marvel or DC movies star.',
		alt: "Swap faces with celebrity Johnson"
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-girl-swapped-faces-in-her-selfie-picture.webp', import.meta.url).href,
		title: 'Selfie Face Swap',
		alt: 'The girl swapped faces in her selfie picture',
		description: 'Discover a new you with virtual try-on.Explore different facial features, hairstyles, makeups, and genders for a fun and creative twist.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-male-friend-swapped-faces-in-the-picture.webp', import.meta.url).href,
		title: 'Friends and Family Face Swap',
		alt: 'The male friend swapped faces in the picture',
		description: 'Revamp your photos with a touch of creativity by changing the faces of your friends and family.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
		title: 'Funny Memes Face Swap',
		alt: 'The face swapped picture generated a funny meme',
		description: 'Get playful with well - aimed memes, such as transforming your boss into the superhero or adding your face to iconic memes.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-good-looking-avatar.webp', import.meta.url).href,
		title: 'Face Swap Avatars',
		alt: 'The face swapped picture generated a good looking avatar',
		description: 'Unlock endless possibilities with customizable avatars and face swap features. Create personalized avatars that truly represent you.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-professional-career-headshot.webp', import.meta.url).href,
		title: 'Face Swap Likeness for Business',
		alt: 'The face swapped picture generated a professional career headshot',
		description: 'Elevate your business with our face swap solution.Attract leads with captivating swaps and create professional AI headshots.'
	}
])

const selectCategory = (category,newIndex) => {
	selectedCategory.value = { category, index:newIndex };
	// selectImage(category.images[0]);
	index.value = index;
};

const selectImage = (image,remoteImage) => {
	console.log('image selected')
	selectedImage.value = image;
	imageUrlB.value = remoteImage
	show_downLoad.value = false
	// console.log('image selected')
};

const openFileInput = () => {
	fileInput.value.click()
	show_downLoad.value = false
}

const openFileTemptInput = () => {
	templateInput.value.click()
	show_downLoad.value = false
}



const handleTemptFileChange = (event) => {
	const files = event.target.files
	isUploading.value = true
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
		// const reader = new FileReader()
		// reader.onload = async (e) => {
		// 	const img_url = await uploadFile(file, 'your template')
		// 	selectedImage.value = img_url
		// 	imageUrlB.value = img_url
		// 	show_downLoad.value = false
    //   imageB.value.unshift({
    //     file,
		// 		url: await fetchImage(img_url),
		// 		remote_url: img_url,
    //   })
    //   // uploadFile(file, 'B')
    // }
		// reader.readAsDataURL(file)
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				const reader = new FileReader();
				reader.onload = async (e) => {
					const img_url = await uploadFile(compressedFile, 'your template');
					selectedImage.value = img_url;
					imageUrlB.value = img_url;
					show_downLoad.value = false;
					imageB.value.unshift({
						file: compressedFile,
						url: await fetchImage(img_url),
						remote_url: img_url,
					});
				};
				reader.readAsDataURL(compressedFile);
			},
			error(err) {
				console.error('Compression error:', err.message);
			},
		});
  }
}


let pollingInterval = null;

const handleFileChange = async (event) => {

	isUploading.value = true
	const file = event.target.files[0]
	// if (file) {
	// 	// imageA.value = URL.createObjectURL(file)
	// 	const img_url = await uploadFile(file, 'your face')
	// 	isImageUploaded.value = true; // 上传图片后改变按钮 B 的颜色
	// 	const url = await fetchImage(img_url)
	// 	imageUrlA.value = url
	// 	imageA.value = url
	// 	console.log("imageUrlA", imageUrlA.value)
	// 	console.log("imageA", imageA.value)

	// }
	if (file) {
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				// 上传压缩后的文件
				uploadFile(compressedFile, 'your face').then((img_url) => {
					isImageUploaded.value = true; // 上传图片后改变按钮 B 的颜色
					fetchImage(img_url).then((url) => {
						imageUrlA.value = url;
						imageA.value = url;
						console.log("imageUrlA", imageUrlA.value);
						console.log("imageA", imageA.value);
						isUploading.value = false; // 上传完成后将 isUploading 设置为 false
					});
				});
			},
			error(err) {
				console.error('Compression error:', err.message);
				isUploading.value = false; // 压缩失败后将 isUploading 设置为 false
			},
		});
	} else {
		isUploading.value = false; // 如果没有文件选择，将 isUploading 设置为 false
	}
}

const uploadFile = async (file, type) => {
	const formData = new FormData()
	formData.append('file', file)
	try {
		const response = await axiosInstance.post('/api/swap_face/upload', formData)
		if (response.status == 200) {
			isUploading.value = false
			return response.data.img_url
		} else {
			alert(type + " image upload faild,please check your picture and reload upload again!")
		}
	} catch (error) {
		alert('image upload faild,please check your picture',error)
		console.error('image upload faild,please check your picture:', error)
		isUploading.value = false
		// return null
	}
}

const pollTaskResult = () => {
	// if (pollingInterval) {
	// 	console.log('轮询任务已存在，不再启动新的轮询任务');
	// 	return;
	// }
	pollingInterval = setInterval(async () => {
		try {
			const response = await axiosInstance.post(`/api/swap_face/result`, {
				'task_id':user_curent_task_id.value,'isLogin':isLoggedIn.value});
			if (response.status === 200 || response.status === 400) {
				console.log("result",response.data)
				const result = response.data;
				if (result.status === 'completed') {
					// 任务完成，获取下载链接
					result_url.value = result.download_url;
					clearInterval(pollingInterval); // 停止轮询
					loading.value = false; // 隐藏动效
					show_downLoad.value = true
				}
			}
		} catch (error) {
			clearInterval(pollingInterval); // 停止轮询
			loading.value = false; // 隐藏动效
		}
	}, 5000); // 每 5 秒轮询一次
};


onUnmounted(() => {
	clearInterval(pollingInterval); // 组件卸载时停止轮询
});

const buttonText = ref(null)
watchEffect(() => {
	if (show_downLoad.value) {
		buttonText.value = "download"
	} else if (loading.value) { 
		buttonText.value = "click backend generates"
	} else {
		buttonText.value = "swap face"
	}
});

const handleClick = async () => {
	if (loading.value) {
		// alert('生成任务已在后台进行，请稍候...');
		if (pollingInterval) {
			clearInterval(pollingInterval);
			console.log('轮询已停止');
		}
		loading.value = false;
		imageUrlA.value = null
		imageUrlB.value = null
		selectedImage.value = null
		imageA.value = null
		isImageUploaded.value = false
		show_downLoad.value = false
		return;
	}

	if (!imageUrlA.value || !imageUrlB.value) {
		alert('please upload image');
		return;
	}
	loading.value = true;
	try {
		if (show_downLoad.value) {
			await downloadImage(result_url.value);
			loading.value = false; // 隐藏动效
		} else {
			if (userCredits.value <= 2) {
				console.log("need buy credists")
				userStore.showSubcrib = true
				loading.value = false
				return
			} else { await generate(2); }
		}
	} catch (error) {
		console.error(error);
		// loading.value = false;
	} finally {
		// loading.value = false;
	}
};


const generate = async (needCredit) => {
	// 通过变量控制是否每过 10s轮询一次/用户点击完成通知我
	result_url.value = null
	if (!imageUrlA.value) { 
		alert("please upload face image")
	}

	if (!imageUrlB.value) {
		alert("please upload template image")
	}
	
	loading.value = true; // 显示动效
	if (imageUrlA.value && imageUrlB.value) {
		try {
			const response = await axiosInstance.post('/api/swap_face/create', {
				udid: user.value.udid,
				image_url: imageUrlA.value,
				template_url: imageUrlB.value,
				task_type: "swap_face",
				task_id: Date.now(),
				is_custom: false,
				isLogin:isLoggedIn.value
			})
			if (response.status == 200) {

				// 任务创建成功
				userStore.consumeCredits(needCredit)
				user_curent_task_id.value = response.data.task_id
				// 开始轮询任务结果
				pollTaskResult();

			} else {
				alert("task faild", response.data.error)
			}
		} catch (error) {
			console.error('image process erro:', error)
		}
	}

}

const questions = ref([
	{
		question: "What is AI face swap?",
		answer: "AI face swap technology replaces faces in photos or videos using artificial intelligence.",
		showAnswer: false
	},
	{
		question: "Do I need technical skills to use a face swapper?",
		answer: "No, you don’t. Pook’s AI face swapping tool is easy to use and requires no other complicated manual operations. You just need to follow our guides to upload two photos. With one simple click, the face swap process will be done within seconds.",
		showAnswer: false
	},
	{
		question: "How does AI face swap work?",
		answer: "It uses AI to detect faces, map their features, and seamlessly blend a new face into the original image or video.",
		showAnswer: false
	},
	{
		question: "Is pook's face swap tool safe?",
		answer: "Yes, it's safe. We only use your photos for face swapping and delete them within 24 hours of upload.",
		showAnswer: false
	},
]);

const toggleAnswer = (index) => {
	questions.value[index].showAnswer = !questions.value[index].showAnswer;
};

const reviews = ref([
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/76c89227-f7ad-4bfa-820d-e9e07c265c4d.webp',
		name: 'jack - College Student',
		stars: 5,
		comment: "This face swap tool is awesome! I created a hilarious 90s yearbook photo of myself and shared it with friends, who all had a good laugh. It's super easy to use and incredibly fast, making it a fun and quick way to transform your photos.",
	},
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/a06dc153-c0f7-4054-a935-f6f58905dc0e.webp',
		name: 'Jamie - Software',
		stars: 5,
		comment: "This tool was a lifesaver for our friend group holiday photo! We usually take a group pic, but couldn't get together this year. So I used the face swap feature to put us all in Christmas sweaters. It's not perfect, but it's fun and kept our tradition alive. The tool is easy to use and made the process quick and enjoyable."
	},
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/136410f1-f473-4f15-be36-dcebe215be62.webp',
		name: 'Alex - Social Media Influencer',
		stars: 5,
		comment: "I've been using this face swap tool for my social media posts. I put myself in some movie scenes, and people really liked the Godfather one. It's quick to use, and the results are pretty good. This tool has given me ideas for content I wouldn't have thought of before, making it a valuable addition to my social media strategy."
	}
]);



// // 定义博客链接数据
// const blogLinks = ref([
// 	{ path: '/blog/1', label: 'Blog 1' },
// 	// { path: '/blog/2', label: 'Blog 2' },
// 	// { path: '/blog/3', label: 'Blog 3' },
// 	// 添加更多链接
// ]);

	
</script>

<script>
export default {
	'name': 'SwapFace'
}
</script>


<template>
	<div class="container">
		<LayerOut>
		</LayerOut>
		<ImageViewer :imageUrl="currentImageUrl" :visible="imageViewerVisible" @close="closeImageViewer" />


		<!-- <div v-if="showLoginPopup" class="popup-overlay" @click="showLoginPopup = false">
			<div class="popup-content" @click.stop>
				<span class="close" @click="showLoginPopup = false">&times;</span>
				<div class="login_box_box">
					<div
						style="font-size: 24px; font-weight: 800; text-align: center;border-radius: 12px;background-color:aliceblue;margin-bottom: 30px;">
						Login in to continue
					</div>
					<button class="login_box" @click="handleLogin('googel')">
						<div class="login_icon"
							style="background-image: url('https://img.js.design/assets/img/66b19e3699dc343afa9145d4.png#4e8f0cc76a43a58bf919d948e95b5247');">
						</div>
						<div class="google_text">
							Continue with Google
						</div>
					</button>
					<button class="login_box" @click="handleLogin('facebook')">
						<div class="login_icon"
							style="background-image: url('https://img.icons8.com/?size=100&id=uLWV5A9vXIPu&format=png&color=000000');">
						</div>
						<div class="google_text">
							Continue with Facebook
						</div>
					</button>
					<button class="login_box" @click="handleLogin('other')">
						<div class="login_icon"
							style="background-image: url('https://img.icons8.com/?size=100&id=X0mEIh0RyDdL&format=png&color=000000');">
						</div>
						<div class="google_text">
							Continue with email
						</div>
					</button>
				</div>
			</div>
	</div> -->
		<!-- <div v-if="showLoginPopup" class="blur-background"></div> -->
		<!-- <StripeCheckout /> -->

		<div class="content_box" ref="contentBox">
			<div class="swap_box content_box_height content_weight">
				<div class="swap_word1">
					Free Face Swap Online
				</div>
				<div class="swap_word2">
					Discover realistic and funny face swap effects with our tool.
				</div>
				<div class="font3_box">
					<button @click="toggleDisplay('swap_face')" :class="{ 'font_name3':true,isActive: activeDiv ==='swap_face'}">
						face swap
					</button>
					<button @click="toggleDisplay('multi_swap_face')" style="display: none;"
						:class="{'font_name3':true,isActive: activeDiv ==='multi_swap_face'}">
						multi face swap
					</button>
					<button @click="toggleDisplay('my_result')" class="font_name3"
						:class="{ 'font_name3': true, 'isActive': activeDiv === 'my_result'}">
						my library
					</button>
				</div>
				<div v-if="activeDiv === 'multi_swap_face'" style="width: 100%;height: auto;">
					multi swap face
				</div>
				<div v-if="activeDiv === 'my_result'"
					style="width: 100%;height: auto; display: flex;flex-wrap: wrap;overflow: scroll;">
					<!-- <img v-for=" (image, index) in selectedCategory.category.images" :key="index" :src="image" alt="Image"
						style="width: 20%;height: auto; float: left;" /> -->
					<img v-for="(image, index) in mylib_result_urls" :key="index" :src="image" alt="Image"
						style="max-width: 128px;max-height: 128px;padding: 10px; float: left;" @click="handleImageClick" />
				</div>
				<div v-if="activeDiv === 'swap_face'" class="row_content row_content_margin">
					<div class="row_content row_content_margin_child row_content_back">
						<button @click="openFileInput" :disabled="isUploading" class="col_content row_content_margin_child"
							style="height: 80%; width: 80%; border: none;">
							<!-- <div class="row_content row_content_margin_child upload_img" style="height: 80%; width: 80%;">
							</div> -->
							<input class="row_content row_content_margin_child" style="display: none;" type="file" ref="fileInput"
								@change="handleFileChange" accept="image/*" />
							<div v-if="isUploading && !imageA" class="loading-spinner"></div>
							<div v-else>
								<img v-if="imageA" :src="imageA" alt="" style="width: 100%;height: auto;">
								<img v-else src="../assets/upload-img.png" alt="" style="width: 100%;height: auto;">
								<!-- <img :src="imageA" alt="" style="width: 100%;height: auto;"> -->
							</div>
							<div> upload your face</div>
						</button>
						<div class="row_content" style="align-items: center; justify-content: center;">
							<svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 12L24 24L12 36" stroke="#080808" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
								<path d="M24 12L36 24L24 36" stroke="#080808" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
							</svg>
						</div>
						<button @click="openFileTemptInput" :disabled="isUploading" class="row_content row_content_margin_child"
							style="border: none;align-items: center;justify-content: center;">
							<div v-if="isUploading && !selectedImage" class="loading-spinner">
							</div>
							<div v-if="selectedImage">
								<img :src="selectedImage" alt="" style="width: 100%;height: auto;">
							</div>
							<div v-else>
								<img src="../assets/upload-img.png" alt="" style="width: 100%;height: auto;">
							</div>
							<input class="row_content row_content_margin_child" style="display: none;" type="file" ref="templateInput"
								@change="handleTemptFileChange" accept="image/*" />
						</button>
						<!-- <button @click="openFileTemptInput" :disabled="isUploading"
							class="row_content row_content_margin_child img upload_img"
							:style="{backgroundImage: `url(${selectedImage})` }">
							<div v-if="isUploading" class="loading-spinner">
							</div>
							<div>
								<img src="${selectedImage}" alt="">
							</div>
							<input class="row_content row_content_margin_child" style="display: none;" type="file" ref="templateInput"
								@change="handleTemptFileChange" accept="image/*" />
						</button> -->
					</div>
					<div style="display: flex;align-items: center;justify-content: center;width: 50%;">
						<img v-if="result_url" :src="result_url" class="row_content row_content_margin_child img"
							@click="handleImageClick">
						<div v-if="!result_url && loading" class="loading-spinner"></div>
					</div>
				</div>
				<button v-if="activeDiv !== 'my_result'"
					:class="{ 'click_generate_upload': isImageUploaded, 'click_generate': !isImageUploaded }"
					@click="handleClick">
					<!-- <span v-if="!loading">{{ buttonText }}</span>
					<span v-else>
						<span class="loading-spinner"></span>
        {{ buttonText }}
      </span> -->
					<span v-if="!loading">{{ buttonText }}</span>
					<span v-else class="loading-spinner"></span>
					<span v-if="loading">{{ buttonText }}</span>
				</button>

				<!-- <button v-if="activeDiv !== 'my_result' && show_downLoad == false"
					:class="{'click_generate_upload': isImageUploaded, 'click_generate': !isImageUploaded }" @click="generate(2)">
					swap face
					<span v-if="loading" class="loading-spinner"></span>
				</button>
				<button v-if="activeDiv !== 'my_result' && show_downLoad == true "
					:class="{ 'click_generate_upload': isImageUploaded, 'click_generate': !isImageUploaded }"
					@click="downloadImage()">
					download
					<span v-if="loading" class="loading-spinner"></span>
				</button> -->
			</div>
			<div class="tempalte_box row_content">

				<div class="template_type">
					<button v-for="(category, index) in categories" :key="index" @click="selectCategory(category,index)"
						:class="{'template_type_select':true,isActive: selectedCategory.category === category}">
						{{ category.name }}
					</button>
				</div>
				<div class="template_img">
					<button class="upload_template" @click="openFileTemptInput">
						<svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
							<mask id="icon-4127d3cd7f10b972" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48"
								style="mask-type: alpha">
								<path d="M48 0H0V48H48V0Z" fill="#f3eeee" />
							</mask>
							<g mask="url(#icon-4127d3cd7f10b972)">
								<path d="M6 24.0083V42H42V24" stroke="#f3eeee" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
								<path d="M33 15L24 6L15 15" stroke="#f3eeee" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
								<path d="M23.9917 32V6" stroke="#f3eeee" stroke-width="2" stroke-linecap="butt"
									stroke-linejoin="round" />
							</g>
						</svg>
						<div
							style="margin-left: 5px;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
							upload
						</div>
					</button>
					<div v-if="selectedCategory" class="content_box_height"
						style="margin-top: 80px;align-items: center;justify-content: center;overflow:auto;margin-right: 10px;">
						<input style="display: none;" type="file" ref="templateInput" @change="handleTemptFileChange" multiple />
						<!-- <div v-for="(img, index) in imageB" :key="index"> -->
						<div v-if="imageB.length">
							<img v-for="(img, index) in imageB" :key="index" :src="img.url" alt="Image B"
								@click="selectImage(img.url,img.remote_url)" :class="{ 'selected-image': selectedImage === img.url }"
								style="max-width: 200px;">
						</div>
						<img v-for=" (image, index) in selectedCategory.category.images" :key="index" :src="image" alt="Image"
							@click="selectImage(image,image)" :class="{ 'selected-image': selectedImage === image }" />
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="img_doc">
			<div class="img_doc1_box content_weight">
				<img src="https://img.js.design/assets/img/66b19e402f035e976606a3d2.png#bac9f7bcbe148302c3015c45012be16e"
					alt="swap face">
			</div>
			<div class="img_doc1_box_box">
				<div class="img_doc1_box_doc1">
					Create Infinite Faces with AI Face Swap Technology
				</div>
				<div class="img_doc1_box_doc2" style="font-size:15px;">
					AI face replacement has surged in popularity as a dynamic photo trend. Its advanced algorithms accurately
					detect and track facial features, allowing users to effortlessly create realistic deep swaps. Pook AI face
					switcher is one of the best free face swap websites, you can use it to seamlessly put face on picture and
					enjoy endless fun while engaging with realistic deepfake photos.
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						swap face
					</button>
				</div>
			</div>
		</div> -->
		<div class="row_doc_box_base">
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Create-Infinite-Faces-with-AI-Face-Swap-Technology.webp')"
					alt="" class="box_img_base">
			</div>
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					Create Infinite Faces with AI Face Swap Technology
				</div>
				<div class="box_text_base2">
					AI Face Replacement Booms as a Dynamic Photo Trend: Advanced Algorithms for Realistic Deep Swaps

					AI face replacement has gained immense popularity as a dynamic photo trend. With its advanced algorithms, it
					accurately detects and tracks facial features, enabling users to effortlessly create realistic deep swaps.
					Pook AI face switcher stands out as one of the best free face swap websites. Users can seamlessly put faces on
					pictures and enjoy endless fun while engaging with realistic deepfake photos.
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						swap face
					</button>
				</div>
			</div>
		</div>
		<div class="doc2_box text">
			Experience AI Face Swap Magic - Switch Your Face with Anyone You Wish
		</div>
		<div class="row_doc_box_base" style="height: auto;">
			<div v-for="(item, index) in personSwapItems" :key="index" class="doc3_box_1"
				style="display: flex;flex-direction: column;">
				<div style="width: auto;height: auto;">
					<img v-lazy="item.imageUrl" :alt="item.alt" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">
						{{ item.title }}
					</div>
					<div class="doc3_box_1_text2">
						{{ item.description }}
					</div>
				</div>
			</div>
		</div>
		<div>
			<button class="swap_button_style box_center" @click="scrollToTop">
				swap face
			</button>
		</div>
		<div class="row_doc_box_base">
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					Swap Face Online Swiftly and Flawlessly
				</div>
				<div class="box_text_base2">
					Discover the future of photo editing with Pook's AI Face Remake, featuring lightning-fast face swapping
					powered by advanced AI technology. Effortlessly transform your photos by replacing faces with anyone else.
					Just upload your images, click "Swap Face," and watch as Pook delivers unprecedented speed, flawless
					precision, and natural results. Enjoy a seamless experience with just a few clicks—no manual adjustments or
					design skills needed. Elevate your photo editing game with Pook's intuitive and powerful AI tools.
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						swap face
					</button>
				</div>
			</div>
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Swap-Face-Online-Swiftly-and-Flawlessly.webp')"
					alt="Swap Face Online Swiftly and Flawlessly" class="box_img_base">
			</div>
		</div>
		<div class="row_doc_box_base">
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Instant-Face-Morphing-with-Our-Trending-Preset-Photos.webp')"
					alt="Instant Face Morphing with Our Trending Preset Photos" class="box_img_base">
			</div>
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					Instant Face Morphing with Our Trending Preset Photos
				</div>
				<div class="box_text_base2">
						Transform your selfies into captivating creations with Pook's AI Face Swapper. Explore a vast array of preset
					options and effortlessly merge your face into new photos. Whether you're seeking humor or unleashing your
					creativity, our intuitive AI tool delivers unparalleled realism and endless entertainment. Simply upload your
					selfie, select a preset, and witness the seamless transformation in seconds.
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						swap face
					</button>
				</div>
			</div>
		</div>
		<div class="row_doc_box_base">
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					Easy and Safe AI Face Replacement
				</div>
				<div class="box_text_base2">
						Pook AI Face Changer: The Trusted, Secure, and Free Deepfake Image Maker for Seamless Face Swapping. Discover
					Pook AI Face Changer, your trusted partner for creating realistic deepfake images. Our advanced AI technology
					ensures your privacy with strict security measures, making it the safest choice for face swapping. Enjoy a
					hassle-free experience with no hidden costs, and rest easy knowing your data is secure. Transform your photos
					effortlessly with just a few taps, and enjoy the peace of mind that comes with using a reputable, free tool
					that prioritizes your safety and security.
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						swap face
					</button>
				</div>
			</div>
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Easy-and-Safe-AI-Face-Replacement.webp')"
					alt="Easy-and-Safe-AI-Face-Replacement" class="box_img_base">
			</div>
		</div>
		<div class="row_doc_box_base">
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Face-Swap-on-the-Go-for-Android-and-iPhone.webp')"
					alt="Face-Swap-on-the-Go-for-Android-and-iPhone" class="box_img_base">
			</div>
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					Free Face Swap App
				</div>
				<div class="box_text_base2">
					Free Mobile Reface App: Face Swap on the Go for Android and iPhone. Swap your face effortlessly with our free mobile reface app, compatible with both Android and iPhone devices. Experience seamless face changes on your mobile and enjoy a user-friendly experience wherever you are. Effortlessly upload your original and target face images from your smartphone with our mobile support.
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						swap face
					</button>
				</div>
			</div>
		</div>
		<div class="doc2_box text">
			How to Swap Face Online
		</div>
		<div class="row_doc_box_base">
			<div class="doc3_box_1">
				<div>
					<img v-lazy="require('../assets/images/swap-face/upload-your-face-to-face-swap.jpg')"
						alt="How to Swap Face Online" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">
						Step 1 Upload Your Face image
					</div>
					<div class="doc3_box_1_text2">
						Click 'Upload' to select your face image.
					</div>
				</div>
			</div>
			<div class="doc3_box_1">
				<div>
					<img v-lazy="require('../assets/images/swap-face/upload-target-face-or-template-to-face-swap.jpg')"
						alt="How to Swap Face Online" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">
						Step 2 Upload template image
					</div>
					<div class="doc3_box_1_text2">
						Upload template image for face swap with target image.
					</div>
				</div>
			</div>
			<div class="doc3_box_1">
				<div>
					<img v-lazy="require('../assets/images/swap-face/clik-face-swap-to-generate.jpg')"
						alt="How to Swap Face Online" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">
						Step 3 Start the face swap
					</div>
					<div class="doc3_box_1_text2">
						Click "Swap Face" to begin. Photos process in seconds, Return anytime to download
						results.
					</div>
				</div>
			</div>
		</div>
		<div>
			<button class="swap_button_style box_center" @click="scrollToTop">
				swap face
			</button>
		</div>
		<!-- <div class="doc2_box text">
			Experience AI Face Swap Magic - Switch Your Face with Anyone You Wish
		</div> -->
		<!-- <div class="row_doc_box_base">
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					Instantly Change Faces in Photos
				</div>
				<div class="box_text_base2">
					Pica AI's online face swap tool lets you easily change faces in any image - photos, cartoons, or artworks. In
					seconds, transform into celebrities, place anyone in exciting new scenes, or create funny memes in seconds.
					Try gender swaps, insert faces into famous paintings, or enhance selfies for trending social media posts. With
					a few clicks, make your images stand out and grab attention. Start face swapping now and bring your creative
					ideas to life!
				</div>
				<div>
					<button class="swap_button_style box_center" @click="scrollToTop">
						swap face
					</button>
				</div>
			</div>
			<div class="fdvfd">
				<img src="https://img.js.design/assets/img/66b19e381a42a181fa842857.png#b9495da98adf8ac491fe185955db4830"
					alt="">
			</div>
		</div> -->
		<div class="doc2_box text">
			What Our Users Say about Pica AI Face Swapper
		</div>
		<div class="row_doc_box_base">
			<div v-for="(review, index) in reviews" :key="index" class="buy_back_box">
				<div class="buy_back_box_text">
					<div>
						<img :src="review.image" alt="">
					</div>
					<div>
						{{ review.name }}
					</div>
				</div>
				<div class="stars">
					<img v-for="star in review.stars" :key="star" src="../assets/Star.png" alt="">
				</div>
				<div class="buy_back_box_text2">
					{{ review.comment }}
				</div>
			</div>
		</div>
		<div
			style="color: #f5f2f4;display: flex;font-size:2rem;font-weight: 400;width: 100%;height: auto;align-items: center;justify-content: center;flex-direction: column;margin-bottom: 2rem;padding-top: 20px;">
			<div>
				FAQS
			</div>
			<div style="font-size: 1rem;">
				Have more questions? Email us at support@imagefaceswap.com.
			</div>
		</div>
		<div
			style="display: flex;flex-direction: column; width: 90%;height: auto; padding: 10px;text-align: left;align-items: center;justify-content: center;">
			<div v-for="(item, index) in questions" :key="index" class="question-item">
				<div class="question-title" @click="toggleAnswer(index)">
					{{ item.question }}
				</div>
				<div v-if="item.showAnswer" class="question-answer">
					{{ item.answer }}
				</div>
			</div>
		</div>
		<footerPage>
		</footerPage>
		<!-- <button class="back-to-top" @click="scrollToTop">Back to Top</button> -->
	</div>
</template>

<style scoped lang="css">

.question-item {
	width: 85%;
	/* text-align: left; */
	margin-left: 0px auto;
	margin-bottom: 10px;
	color: #1f1f1f;
	/* border: #a7b0ba; */
	padding: 10px;
	border-radius: 5px;

}

.question-title {
	background: #f5f2f4;;
	width: 90%;
	padding: 20px;
	height: 1.3rem;
	cursor: pointer;
	font-weight: bold;
	border-radius: 10px;
}

.question-answer {
	color: #b8b3b3;
	margin-top: 10px;
	padding-left: 20px;
}

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	position: relative;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}

form {
	display: flex;
	flex-direction: column;
}

label {
	margin-top: 10px;
}

input {
	padding: 10px;
	margin-top: 5px;
	/* border: 1px solid #ccc; */
	border-radius: 3px;
}

button[type="submit"] {
	margin-top: 20px;
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.blur-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	z-index: 999;
}

.login_box_box {

	width: 400px; 
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.3);
}

.login_box {
	width: 80%;
	height: 20%;
	display: flex; 
	flex-direction: row;
	margin: 10px auto ;
	/* margin-left: 15%; */
	background-color: #484848;
	align-items: center;
	border-radius: 12px;
	justify-content: center;
	cursor: pointer;
	
	
}

.google_text {
	/*gontinue with google*/
	
		/* left: 107px;
		top: 3px; */
		width: 90%;
		height: auto;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 800;
		align-items: center;
		align-content: space-between;
		letter-spacing: 0px;
		line-height: 20px;
		color: #f5f2f4;
		vertical-align: top;
		/* border: #007bff solid 1px; */
}

.login_icon {
	width: 31px;
	height: 31px;
	/* margin-left: 15px; */
	background-size: 90%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	/* border:#f60e44 solid 1px; */
	/* margin-left: 10px; */
	margin-right: 10px;
}

.content {
	padding: 20px;
}

/* .container {
	display: flex;
	align-items: center;
	padding: 20px;
} */

.container {
	/*路径*/
	
		left: 0px;
		top: 0px;
		width:auto;
		height: auto;
		background: url("../assets/background.png");
		overflow: hidden;
		
}

.content_box_height {
	height: 650px;
	/* border: #de11e5 solid 1px; */
}

.topcontainer {

	/* border: #007bff solid 1px; */
	overflow: hidden;
	width: auto;
	height: 60px;
	background: url("../assets/daohanglan.png");
	/* overflow: hidden; */
	/* padding: auto; */
	display: flex;
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	/* 设置高度，例如： */
	/* height: 100vh; */
}

.logotype{
	/* border: #343a40 solid 1px; */
	/* float: left; */
	margin-left:-1.8vw ;
	margin-right: 20px;
	margin-top: 20px;
	/* background-image: url("../assets/face_swapping_logo.png"); */
	background-size: contain;
	width: auto; /* 父容器宽度 */
  height: auto; /* 父容器高度，可以根据需要调整 */
  background-position: center; /* 图片居中 */
  background-repeat: no-repeat; /* 防止图片重复 */
	overflow: hidden;
	/* border-radius: 50%; */
	/* border:#007bff solid 1px; */
	
}

.logotype img {
	width: 15vw;
	height: auto;
}

.linktype {
	/* border: #f462c8 solid 1px; */
	display: flex;
	/* justify-content:center; */
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	width: 70%;
	margin-left: 0px;
	/* 设置高度，例如： */
	/* height: 100vh; */
}

.gongneng {
	/* border: #343a40 solid 1px; */
	display: flex;
	float: left;
	margin-left: 0px;
	margin-right: 30px;
	padding: 20px 0;
	/*sign up*/

	/* font-size: 24px; */
	font-weight: 200;
	letter-spacing: 0px;
	line-height: 26px;
	color: rgb(248, 238, 238);
	align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
	text-align: center;
	vertical-align: top;
	background-color: transparent;
	border: none;
	cursor: pointer;
	/* outline: none; */
}

.gongneng span {
	white-space: nowrap;
	/* 防止文字换行 */
	font-size: 1.5em;
	/* 使用相对单位 */
}

.dropdown {
	
	display: flex;
	position: absolute;
	top: 30px;
	right: 0;
	width: auto;
	height: 200px;
	background-color: rgb(63, 62, 63);
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
	color: #f5f2f4;
	padding: 10px;
	z-index: 1000;
	flex-direction: column;
	margin: 20px auto;
	/* border: none; */
	cursor: pointer;
	/* border: #007bff solid 1px; */
	gap: 10px;
	overflow: hidden;
}

 .dropdown button {
		width: 120px;
		/* 控制按钮宽度 */
		height: 40px;
		/* 控制按钮高度 */
		padding: 0;
		/* 去除默认 padding */
		font-size: 16px;
		/* 控制字体大小 */
		border: none;
		/* 去除默认边框 */
		background-color: #6e7072;
		/* 按钮背景颜色 */
		color: white;
		/* 按钮文字颜色 */
		cursor: pointer;
		/* 鼠标悬停时显示为指针 */
		border-radius: 5px;
		/* 按钮圆角 */
 }

.gongneng:hover {
	color: #976af2;
	/* 悬浮时背景颜色变化 */
	transition: color 0.3s ease, transform 0.3s ease;
	/* transform: translateY(-5px); */
	/* 悬浮时向上移动 */
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
	/* 悬浮时添加阴影 */
	}

 .gongneng:active {
	background-color: transparent;
 }



.logintype {
	/* border: #00ff40; */
	float: right;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: 30px;
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0;
}

.content_box {
	/* float: left; */
	/* border: #f5f2f4 solid 1px; */
	display: flex;
	/* align-items: center; */
	overflow: hidden;
	/* position: relative; */
	
}

.content_weight {
	margin-left: 70px;
}

.swap_box {
	/*路径*/
		/* left: 183px;
		top: 220px; */
		position:relative;
		display:flex;
		margin-top: 80px;
		margin-right: 20px;
		height: auto;
		align-items: center;
		flex-direction: column;
		/* justify-content:center; */
		/* text-align: center; */
		/* justify-content:flex-end; */
		width: 65%;
		/* opacity: 1; */
		/* border: #ef24c0 solid 1px; */
		background: rgb(249, 250, 250);
}

.col_content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.col_content2 {
	position: relative;
	display: flex;
	/* align-items:center; */

	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.row_content_back {
	background: rgb(247, 242, 242);
	/*路径*/
}

.row_content {
		position: relative;
		display: flex;
		/* align-items: center;
		justify-content:center; */
		flex-direction:row;
		/* border: hsl(167, 67%, 67%) solid 1px; */
}

.row_content_margin {

	margin-left: 10px;
	margin-top: 10px;
	width: 100%;
	height: 50%;
}

.row_content_margin_child {

	margin-left: 10px;
	margin-top: 10px;
	margin-right: 20px;
	position: relative;
	width: 55%;
	height: 90%;
	object-fit: cover;
	border-radius: 12px;
	/* overflow: hidden; */
	/* background-size: 100% 100%; */
}

.click_generate {

	/*路径*/
	
	/* left: 383px;
	top: 843px; */
	margin-top: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 4vw;
	font: 100 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;
	opacity: 1;
	background: rgb(158, 156, 156);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;

}

.click_generate_upload {

	/*路径*/

	/* left: 383px;
	top: 843px; */
	margin-top: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 4vw;
	font: 300 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;;
	opacity: 1;
	background: rgb(16, 127, 238);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;

}

/* .loading-spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-top-color: #3498db;
	animation: spin 1s linear infinite;
} */
 .loading-spinner {
  width: 36px;
  height: 36px;
  background-image: url('../assets/loading2.png'); /* 替换为你的图片路径 */
  background-size: cover;
  animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.img {

	/* max-width:  90%;
	height: 90%; */ 
	/* 可以根据需要调整高度 */
	/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
	/* background-size: 80%; */
	background-position:center;
	background-repeat: no-repeat;
	background-size: contain;/* 保持图片原始宽高比，覆盖整个容器 */
	/* border: #007bff solid 1px; */
	
	

	/* overflow: hidden; */

}

.upload_img {
	/* width: 60%;
	/* height: 90%; */

	background-size: 60% auto;
	background-position: center;
	background-repeat: no-repeat;
	object-fit: cover; 
	background-image: url("../assets/upload-img.png");
	background-color: transparent;
	border: none;
	cursor: pointer;

}

.upload_img:hover {
	color: #976af2;
	/* 悬浮时背景颜色变化 */
	transition: color 0.3s ease, transform 0.3s ease;
	transform: translateY(-5px);
	/* 悬浮时向上移动 */
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
	/* 悬浮时添加阴影 */
}



.tempalte_box {
	/*路径*/
	
		/* left: 1273px;
		top: 97px; */
		position: relative;
		display: flex;
		margin-left: auto;
		margin-top: 80px;
		margin-right: 30px;
		/* margin-bottom: 900; */
		width: 30%;
		height: auto;
		opacity: 1;
		/* border: #7610f2 solid 1px; */
		background: rgba(21, 14, 38, 1);
}
.swap_word1 {
	/*free face swap online*/
	
		/* left: 435px;
		top: 241px; */
		/* margin-top: 30px; */
		width: 100%;
		height: auto;
		text-align: center;
		/* opacity: 1; */
		/* display: flex; */
		/* position: absolute; */
		/** 文本1 */
		font-size: calc(50px + 0.5vw);
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 72px;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		/* border:#007bff solid 1px */
}

.swap_word2 {
	/*experience fun or real swaps: select sin*/
	
		/* left: 290px;
		top: 329px;
		width: 810px;
		height: 29px; */
		/* position: absolute; */
		display: flex;
		justify-content: center;
		opacity: 1;
		/** 文本1 */
		margin-top: 30px;
		display: flex;
		font-size: calc(20px + 0.5vw);
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 29px;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		/* border: #ef24c0 solid 1px; */
}

.font_name3 {
	/*face swap*/
	
		/* left: 462px;
		top: 373px; */
		/* width: 118px;
		height: 35px; */
		opacity: 1;
		position: relative;
		/** 文本1 */
		margin-top: 3px;
		margin-left: 30px;
		margin-right: 30px;
		display: flex;
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 35px;
		color: rgba(112, 112, 112, 1);
		text-align: right;
		vertical-align: top;
		/* border: #00ff40 solid 1px; */
		background-color: transparent;
		border: none;
		cursor: pointer;
}

.font_name3.isActive::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 3px;
	background:linear-gradient(to right, #1f1f1f, #007bff);
	border-radius: 8px;
	bottom:-10px
}

.font3_box {
	/* border: #00ff40 solid 1px; */
	margin-top: 30px;
	margin-left: 30px;
	display: flex;
	flex-direction:row;
	justify-content: space-between;
	align-items: center;
	/* margin-top: 20px;
	margin-left: 30px; */
	/* justify-content: space-between; */
	/* float:inline-start; */
	width: 90%;
	height: auto;
}

.template_img {
	position: relative;
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	/* border: #00ff40 solid 1px; */
	/* margin-bottom: 10px; */
}

.template_img img {
	position: relative;
	width: calc(50% - 20px);
	height: auto;
	justify-content: space-between;
	margin: 5px;
	/* margin-top: 50px; */
	/* margin-bottom: 10px; */
	/* width: 20%; */
	object-fit:cover;
	border-radius: 10px;
	/* 50%宽度减去间距 */
	box-sizing:border-box;
	align-items: center;
	
}

.selected-image {
	border-color: #b07ee2 solid 2px;
}

.template_type {

	display: flex;
	width: 100%;
	height: auto;
	position: relative;
	margin-top: 80px;
	display: flex;
	background: rgba(44, 39, 56, 1);
	/* align-items:center; */
	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.template_type_select {

	/*sporty*/
	
	/* left: 26px;
	top: 5.5px;
	width: 50px;
	height: 23px;
	opacity: 1; */
	/** 文本1 */
	display: flex;
	position:relative;
	margin: 5px;
	/* padding: auto; */
	text-align: center;
	align-items: center;
	justify-content:center;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 23px;
	color: #f5f2f4;
	/* border: none; */
	/* background: seashell; */
	vertical-align: top;
	background-color: #01080f;
	cursor: pointer;

	/*路径*/
	
	/* left: 0px;
	top: 0px; */
	width: 102px;
	height: 35px;
	/* opacity: 1; */
	/* border: 1px solid rgba(72, 52, 212, 1); */
	background-color: transparent;
	border: none;
	cursor: pointer;

}

/* .template_type_select:hover { */
	/* background-color: rgb(246, 246, 248); */
	/* 悬停时背景颜色变为深蓝色 */
/* } */


.template_type_select.isActive {
	/*路径*/
	
		/* left: 0px;
		top: 0px; */
		/* content: ''; */
		/* position: absolute; */
		/* left: 0; */
		/* width: 102px;
		height: 35px; */
		/* opacity: 1; */
		background: rgba(104, 109, 224, 1);
		box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
		z-index: 2;
}

.upload_template {
	position:absolute;
	display: flex;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: 20px;
	flex-direction: row;
	justify-content:center;
	align-items: center;
	width: 90%;
	height: 30px;

	/*路径*/
	/* width: 506px; */
	/* height: 40px; */
	/* opacity: 1; */
	background: rgba(204, 204, 204, 0.3);

	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;;
	vertical-align: top;
	/* border: #007bff solid 1px; */
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	/* cursor: pointer; */
}

.img_doc {
	width: auto;
	height: 40vw;
	display: flex;
	/* position: relative; */
	/* border: #00ff40 solid 1px; */
	/* align-items: center;
	justify-content: center; */
	flex-direction: row;

}

.img_doc1_box {
	/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
		display: flex;
		margin-top: 5vw;
		overflow: hidden;
		width: 50%;
		height: 419px;
	}


.img_doc1_box_box {
	/*create infinite faces with al face swap technology*/

	/* left: 915px; 
		top: 1168px; */
	/* margin-top: 50%;
	margin-left: 50%; */
	/* margin: 10px; */
	/* float: left; */
	position: relative;
	display: flex;
	width: 50%;
	margin-top: 5vw;
	height: auto;
	opacity: 1;
	/* border: #ff0055 solid 1px; */
	flex-direction: column;
	align-items: center;
	justify-content: center;

}
.img_doc1_box_doc1 {
	/*create infinite faces with al face swap technology*/
	
		/* left: 915px; 
		top: 1168px; */
		margin-top: 0vw;
		margin-left: 1vw;
		position: absolute;
		display: flex;
		width: 90%;
		height: auto;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0px;
		color: #f5f2f4;;
		vertical-align: top;
		/* border: #007bff solid 1px; */
}

.img_doc1_box_doc2 {

/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while engaging with realistic face swap photos.*/

margin-top: 8vw;
margin-left: 1vw;
width: 90%;
height: auto;
opacity: 1;
position: absolute;
display: flex;
/** 文本1 */
font-size: 26px;
font-weight: 400;
letter-spacing: 0px;
/* line-height: 12px; */
color: #f5f2f4;;
vertical-align: top;
/* border: #f462c8 solid 1px; */
}

.swap_boten {
	display: flex;
	position: absolute;
	margin-top: 20vw;
	margin-left: 10vw;
	width: 358px;
	height: 92px;
	opacity: 1;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border: none;
}

.swap_boten.text {
	
	width: 178px;
	height: 38px;
	opacity: 1;
	/** 文本1 */
	font-size: 26px;
	font-weight: 400;
	align-items: center;
	justify-content: center;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;;
	text-align: center;
	vertical-align: top;
}

.doc2_box {
		/* left: 500px;
		top: 1886px; */
		/* margin-left: 5vw; */
		
		display: flex;
		align-items: center;
		justify-content: center;
		/* margin-top: 2vw; */
		margin: 2vw auto;
		width: 90%;
		height: 160px;
		opacity: 1;
		font-size: 40px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 60px;
		color: #f5f2f4;;
		/* background: #d7e3f0; */
		vertical-align: top;
		text-align: center;
		/* 文字向左对齐 */
		word-wrap: break-word;
		/* 允许长单词或 URL 地址换行到下一行 */
		/* border: #7610f2 solid 1px;; */
}

.row_doc_box_base {

	display:flex;
	position: relative;
	float: left;
	margin-top: 10px;
	margin: 10px auto;
	width: auto;
	height: auto;
	/* opacity: 1; */
	flex-direction: row;
	/* overflow: hidden; */
	flex-wrap: wrap;
	/* 允许换行 */
	justify-content: space-between;
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

.col_doc_box_base {

	display: flex;
	position: relative;
	float: left;
	margin-top: 10px;
	width: auto;
	height: 40vw;
	opacity: 1;
	flex-direction: column;
	/* overflow: hidden; */
	/* flex-wrap: wrap; */
	/* 允许换行 */
	/* justify-content: space-between; */
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

/* 第一块图文 */
.doc3_box_1 {

	/*rectangle 13*/
	
	
	/* position: relative; */
	/* margin-top: 10px; */
	/* margin-left: 5vw; */
	margin: 10px auto;
	/* left: 0px;
	top: 100px; */
	width: 31%;
	height: 40%;
	/* opacity: 1; */
	/* background: rgba(40, 25, 65, 1); */
	/* border: #ef24c0 solid 1px; */
}

.doc3_box_1_image {
	max-width: 65%;
	/* 图片最大宽度为容器的100% */
	max-height: 65%;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
	overflow: hidden;
}

.doc3_box_1_text_box {

	opacity: 1;
	border-radius: 10px;
	background: rgba(255, 255, 255, 1);
	height: 38%;
}


.doc3_box_1_text {


/*ai face swap has surged in popularity*/

	/* left: 83px;
	top: 2399px;
	width: 474px;
	height: 40px;
	opacity: 1; */
	/** 文本1 */

	font-size: 1.4em;
	font-weight: 350;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	text-wrap:wrap ;
	/* 允许长单词或 URL 地址换行到下一行 */
	/* font-size: 24px; */
	/* color: #f5f2f4; */
	/* border: #ff0055 solid 1px;; */
}


.doc3_box_1_text2 {

	/** 文本1 */
	font-size: 1em;
	font-weight: 00;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	text-wrap:wrap ;

}

.swap_button_style {
	width: 8em;
	height: 2em;
	opacity: 1;
	font-size: 1.3em;
	font-weight: 100;
	color: #f5f2f4;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border-radius: 15px;
	border: none;
}

.box_center {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
}

/* 第3块图文 */
.box_text_base {

	margin-left: 5vw;
	font-size: 1.4em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(250, 247, 247);
	vertical-align: top;
	text-align: center;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	word-wrap: break-word;

}


.box_text_base2 {

	/** 文本1 */
	margin-top: 2vw;
	margin-left: 5vw;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(248, 245, 245);
	vertical-align: top;
	word-wrap: break-word;

}

.box_img_base {

	/* max-width: 50%; */
	/* 图片最大宽度为容器的100% */
	float: left;
	max-width: 90%;
	max-height: auto;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
}

.fdvfd {
	/*rectangle 14*/
	
		/* left: 1104px;
		top: 3571px; */
		/* margin-top: 7vw; */
		margin: 6vw auto;
		width: 616px;
		height: 453px;
		opacity: 1;
		background: rgba(217, 217, 217, 0);
		border: 2px solid rgba(79, 142, 217, 1);
		/* overflow: hidden; */
		/* object-fit:auto; */
}


.buy_back_box {
	/*rectangle 23*/
	
		/* left: 109px;
		top: 8298px; */
		display: flex;
		/* position: absolute; */
		width: 30%;
		height: auto;
		opacity: 1;
		border-radius: 8px;
		background: #c2cad2;
		flex-direction: column;
		border-radius: 12px;
		align-items: center;
		/* justify-content: center; */
		/* background: rgba(255, 255, 255, 1); */
		/* border: #007bff solid 1px; */
}
	
.buy_back_box_text {
		/*free and quaility face swaps*/
	
		/* left: 119px;
		top: 8322px; */
		/* width: 470px;
		height: 46px; */
		display: flex;
		/* justify-items: center; */
		align-items: center;
		/* justify-content: space-between; */
		/* margin: 2px auto; */
		width: 100%;
		height: 60px;
		border-radius: 12px;
		/** 文本1 */
		font-size: 1.5rem;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(11, 11, 11);
		vertical-align: top;
		background-color: #f5f2f4;
	
}

.buy_back_box_text2 {
	/*free and quaility face swaps*/
	/* margin: 2px auto; */
	/** 文本1 */
	display: flex;
	/* margin-top: 50px; */
	width: 80%;
	height: auto;
	padding: 1em;
	font-size: 1rem;
	font-weight: 300;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(25, 24, 24);
	vertical-align: top;
	flex-wrap: wrap;
	text-align: left;
	align-items: center;
	justify-content: center;
	border-radius: 12px;

	/* overflow: scroll; */

}

.buy_back_box_text img {

	padding: 10px;

	width: 50px;
	/* 设置图片的宽度 */
	height: 50px;
	/* 设置图片的高度 */
	border-radius: 50%;
	/* 将图片裁剪成圆形 */
	object-fit: cover;
	/* 确保图片内容被裁剪成圆形 */
	margin-left: 10px;
}

.stars {
	/*star 1*/
	/* position: absolute; */
	
	margin: 1vw auto;
	/* border: #00ff40 solid 1px; */
	/* width: auto; */
	/* height: 30px; */
	/* display: flex; */
	margin-top: 10px;
	width: 90%;
	height: auto;
	text-align: center;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	/* object-fit: cover; */
}

.faq {
	
		/*1.can i face swap pnline for free*/
		display: flex;
		align-items: center;
		justify-content: left;
		margin: 5vw auto;
		width: 80%;
		height: 46px;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 46px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		/* border: #a694a0 solid 1px; */
}

.conect_us {

	display: flex;
	align-items: center;
	justify-content: left;
	/* margin: 30px auto; */
	flex-direction: row;
	color: #f5f2f4;
	letter-spacing: 2px;
	word-spacing: 2px;
	border-top: #3e3f3e solid 2px;

}

.connect_us_box {
	display: flex;
	margin: 10px;
}

/* 小屏幕设备 */
@media screen and (max-width: 720px) {

	.container {
		display: flex;
		flex-direction: column;
		}
	
		.content_box_height {
			height: 400px;
			/* border: #de11e5 solid 1px; */
		}
	
		.topcontainer {
			height: 10vw;
			/* border: #f5f2f4 solid 2px; */
		}
	
		.logotype {
			/* border: #343a40 solid 1px; */
			/* float: left; */
			margin-left: -5vw;
			margin-top: 10px;
			width: auto; /* 父容器宽度 */
			height: auto; /* 父容器高度，可以根据需要调整 */
			overflow: hidden;
		}

		.logotype img {
			width: 30vw;
			height: auto;
		}

		.gongneng {
			/* border: #343a40 solid 1px; */
			float: left;
			margin-left: 0px;
			margin-right: 10px;
			padding: 6px 0;
			/*sign up*/
	
			font-size: 12px;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: 10px;
			color: rgb(59, 57, 57);
			text-align: center;
			vertical-align: top;
			background-color: transparent;
			border: none;
			cursor: pointer;
			/* border:#ee0505 solid 1px; */
			/* outline: none; */
		}
	
		.gongneng:hover {
			color: #976af2;
			/* 悬浮时背景颜色变化 */
			transition: color 0.3s ease, transform 0.3s ease;
			transform: translateY(0px);
			/* 悬浮时向上移动 */
			/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
			/* 悬浮时添加阴影 */
		}
	
		.gongneng:active {
			background-color: transparent;
		}
	
		.linktype {
			display: none;
		}

		.logintype {
			/* border: #00ff40; */
			float: right;
			display: flex;
			align-items: center;
			margin-left: auto;
			margin-right: 15px;
			font-size: 12px;
			font-weight: 200;
			padding: 8px 0;
		}
	
		.content_box {
			/* float: left; */
			/* border: #f5f2f4 solid 1px; */
			display: flex;
			/* align-items: center; */
			overflow: hidden;
			/* position: relative; */
			flex-direction: column;
	
		}
	
		.content_weight {
			margin-left: 30px;
		}
	
		.swap_box {
			margin-top: auto;
			margin-right: 0px;
			margin-left: 0%;
			width: auto;
			overflow: hidden;
			/* border: #de11e5 solid 1px; */
		}
	
		.col_content {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			/* border: #ef24c0 solid 1px; */
		}
	
		.col_content2 {
			position: relative;
			display: flex;
			/* align-items:center; */
	
			flex-direction: column;
			/* border: #ef24c0 solid 1px; */
		}
	
		.row_content_back {
			background: rgb(247, 242, 242);
			/*路径*/
		}
	
		.row_content {
			position: relative;
			display: flex;
			/* align-items: center;
			justify-content:center; */
			flex-direction: row;
			/* border: hsl(167, 67%, 67%) solid 1px; */
		}
	
		.row_content_margin {
	
			margin-left: 10px;
			margin-top: 10px;
			width: 100%;
			height: 50%;
		}
	
		.row_content_margin_child {
	
			margin-left: 10px;
			margin-top: 10px;
			margin-right: 20px;
			position: relative;
			width: 55%;
			height: 90%;
			object-fit: cover;
			border-radius: 12px;
			/* overflow: hidden; */
			/* background-size: 100% 100%; */
		}
	
		.click_generate {
	
			/*路径*/
	
			/* left: 383px;
		top: 843px; */
			margin-top: 5vw;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 70%;
			height: 8vw;
			font: 100 12px/1.5 PingFang SC, sans-serif;
			color: rgba(255, 255, 255, 1);
			opacity: 1;
			background: rgb(158, 156, 156);
			border-radius: 20px;
			cursor: pointer;
			/* background-color: transparent; */
			border: none;
			cursor: pointer;
			/* border: orchid solid 1px; */
	
		}
	
		.click_generate_upload {
	
			/*路径*/
	
			/* left: 383px;
		top: 843px; */
			margin-top: 5vw;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 70%;
			height: 8vw;
			font: 300 18px/1.5 PingFang SC, sans-serif;
			color: rgba(255, 255, 255, 1);
			opacity: 1;
			background: rgb(16, 127, 238);
			border-radius: 20px;
			cursor: pointer;
			/* background-color: transparent; */
			border: none;
			cursor: pointer;
	
		}
	
		.img {
	
			/* width: 90%;
		height: 90%; */
			/* 可以根据需要调整高度 */
			/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
			background-size: 80%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			max-width: 100px;
			/* 保持图片原始宽高比，覆盖整个容器 */
			/* border: #007bff solid 1px; */
	
	
	
			/* overflow: hidden; */
	
		}
	
		.upload_img {
			/* width: 60%;
		/* height: 90%; */
	
			background-size: 50%;
			background-position: center;
			background-repeat: no-repeat;
			object-fit: cover;
			background-image: url("../assets/icon-upload.368442cb.png");
			background-color: transparent;
			border: none;
			cursor: pointer;
			/* border:#976af2 solid 1px; */
	
		}
	
		.upload_img:hover {
			color: #976af2;
			/* 悬浮时背景颜色变化 */
			transition: color 0.3s ease, transform 0.3s ease;
			transform: translateY(-5px);
			/* 悬浮时向上移动 */
			/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
			/* 悬浮时添加阴影 */
		}
	
	
	
		.tempalte_box {
			/*路径*/
	
			/* left: 1273px;
			top: 97px; */
			position: relative;
			display: flex;
			margin-left: 10px;
			margin-top: 10px;
			margin-right: 10px;
			/* margin-bottom: 900; */
			width: 100%;
			height: auto;
			opacity: 1;
			/* border: #7610f2 solid 1px; */
			background: rgba(21, 14, 38, 1);
		}
	
		.swap_word1 {
			/*free face swap online*/
	
			/* left: 435px;
			top: 241px; */
			/* margin-top: 30px; */
			width: 100%;
			height: auto;
			text-align: center;
			/* opacity: 1; */
			/* display: flex; */
			/* position: absolute; */
			/** 文本1 */
			font-size: 2em;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 40px;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			/* border:#007bff solid 1px */
		}
	
		.swap_word2 {
			/*experience fun or real swaps: select sin*/
	
			/* left: 290px;
			top: 329px;
			width: 810px;
			height: 29px; */
			/* position: absolute; */
			display: flex;
			justify-content: center;
			opacity: 1;
			/** 文本1 */
			width: 100%;
			text-align: center;
			margin-top: 20px;
			display: flex;
			font-size: 1em;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 15px;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			/* border: #ef24c0 solid 1px; */
		}
	
		.font_name3 {
			/*face swap*/
	
			/* left: 462px;
			top: 373px; */
			/* width: 118px;
			height: 35px; */
			opacity: 1;
			position: relative;
			/** 文本1 */
			margin-top: px;
			margin-left: 15px;
			margin-right: 15px;
			display: flex;
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 0px;
			line-height: 10px;
			color: rgba(112, 112, 112, 1);
			text-align: right;
			vertical-align: top;
			/* border: #00ff40 solid 1px; */
			background-color: transparent;
			border: none;
			cursor: pointer;
			/* border: #976af2 solid 1px */
		}
	
		.font_name3.isActive::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 3px;
			background: linear-gradient(to right, #1f1f1f, #007bff);
			border-radius: 5px;
			bottom: -5px
		}
	
		.font3_box {
			/* border: #00ff40 solid 1px; */
			margin-top: 8px;
			margin-left: 30px;
			margin-bottom: 8px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			/* margin-top: 20px;
		margin-left: 30px; */
			/* justify-content: space-between; */
			/* float:inline-start; */
			width: 90%;
			height: auto;
			/* border: #007bff solid 1px */
		}
	
		.template_img {
			position: relative;
			display: flex;
			gap: 5px;
			flex-wrap: wrap;
			/* border: #00ff40 solid 1px; */
			/* margin-bottom: 10px; */
		}
	
		.template_img img {
			position: relative;
			width: calc(50% - 20px);
			height: auto;
			justify-content: space-between;
			margin: 5px;
			/* margin-top: 50px; */
			/* margin-bottom: 10px; */
			/* width: 20%; */
			object-fit: cover;
			border-radius: 10px;
			/* 50%宽度减去间距 */
			box-sizing: border-box;
			align-items: center;
	
		}
	
		.selected-image {
			border-color: #b07ee2 solid 2px;
		}
	
		.template_type {
	
			display: flex;
			width: 100%;
			height: auto;
			position: relative;
			margin-top: 80px;
			display: flex;
			background: rgba(44, 39, 56, 1);
			/* align-items:center; */
			flex-direction: column;
			/* border: #ef24c0 solid 1px; */
		}
	
		.template_type_select {
	
			/*sporty*/
	
			/* left: 26px;
		top: 5.5px;
		width: 50px;
		height: 23px;
		opacity: 1; */
			/** 文本1 */
			display: flex;
			position: relative;
			margin: 5px;
			/* padding: auto; */
			text-align: center;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 23px;
			color: rgba(255, 255, 255, 1);
			/* border: none; */
			/* background: seashell; */
			vertical-align: top;
			background-color: #01080f;
			cursor: pointer;
	
			/*路径*/
	
			/* left: 0px;
		top: 0px; */
			width: 102px;
			height: 35px;
			/* opacity: 1; */
			/* border: 1px solid rgba(72, 52, 212, 1); */
			background-color: transparent;
			border: none;
			cursor: pointer;
	
		}
	
		/* .template_type_select:hover { */
		/* background-color: rgb(246, 246, 248); */
		/* 悬停时背景颜色变为深蓝色 */
		/* } */
	
	
		.template_type_select.isActive {
			/*路径*/
	
			/* left: 0px;
			top: 0px; */
			/* content: ''; */
			/* position: absolute; */
			/* left: 0; */
			/* width: 102px;
			height: 35px; */
			/* opacity: 1; */
			background: rgba(104, 109, 224, 1);
			box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
			z-index: 2;
		}
	
		.upload_template {
			position: absolute;
			display: flex;
			margin-top: 20px;
			margin-bottom: 50px;
			margin-left: 20px;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 90%;
			height: 30px;
	
			/*路径*/
			/* width: 506px; */
			/* height: 40px; */
			/* opacity: 1; */
			background: rgba(204, 204, 204, 0.3);
	
			/** 文本1 */
			font-size: 20px;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 38px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			/* border: #007bff solid 1px; */
			cursor: pointer;
			/* background-color: transparent; */
			border: none;
			/* cursor: pointer; */
		}
	
		.img_doc {
			width: auto;
			height: 100vw;
			display: flex;
			/* position: relative; */
			/* border: #00ff40 solid 1px; */
			/* align-items: center;
		justify-content: center; */
			flex-direction: column;
	
		}
	
		.img_doc1_box {
			/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
			display: flex;
			margin-top: 5vw;
			overflow: hidden;
			width: 100%;
			height: 300px;
		}
	
	
		.img_doc1_box_box {
			/*create infinite faces with al face swap technology*/
	
			/* left: 915px; 
			top: 1168px; */
			/* margin-top: 50%;
		margin-left: 50%; */
			/* margin: 10px; */
			/* float: left; */

			display: flex;
			width: 100%;
			margin-top: 5vw;
			align-items: center;
			padding: auto;
			height: auto;
			opacity: 1;
			flex-direction: column;
			/* border: #007bff solid 1px; */
		}
	
		.img_doc1_box_doc1 {
			/*create infinite faces with al face swap technology*/
	
			/* left: 915px; 
			top: 1168px; */
			/* margin-top: 3vw;
			margin-left: 3vw; */
			display: flex;
			width: 90%;
			height: auto;
			opacity: 1;
			padding: auto;
			/** 文本1 */
			font-size: 10px;
			font-weight: 200;
			letter-spacing: 0px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			text-wrap: wrap;
			/* border: #007bff solid 1px; */
		}
	
		.img_doc1_box_doc2 {
	
			/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while engaging with realistic face swap photos.*/
	
		
			width: 90%;
			height: auto;
			opacity: 1;
			display: flex;
			/** 文本1 */
			font-size: 13px;
			font-weight: 200;
			letter-spacing: 0px;
			/* line-height: 12px; */
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			text-wrap:wrap;
			/* border: #f462c8 solid 1px; */
		}
	
		.swap_boten {
			display: flex;
			margin-left: 10vw;
			width: 200px;
			height: 92px;
			opacity: 1;
			background: rgba(72, 52, 212, 1);
			/* border: #00ff40 solid 1px; */
			cursor: pointer;
			border: none;
		}
	
		.swap_boten.text {
	
			width: 178px;
			height: 38px;
			opacity: 1;
			/** 文本1 */
			font-size: 20px;
			font-weight: 300;
			align-items: center;
			justify-content: center;
			letter-spacing: 0px;
			line-height: 38px;
			color: rgba(255, 255, 255, 1);
			text-align: center;
			/* vertical-align: top; */
		}
	
		.doc2_box {
			/* left: 500px;
			top: 1886px; */
			/* margin-left: 5vw; */
	
			display: flex;
			align-items: center;
			justify-content: center;
			/* margin-top: 2vw; */
			margin: 2vw auto;
			width: 90%;
			height: 160px;
			opacity: 1;
			font-size: 1.5em;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 30px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			text-align: center;
			/* 文字向左对齐 */
			word-wrap: break-word;
			/* 允许长单词或 URL 地址换行到下一行 */
			/* border: #7610f2 solid 1px;; */
		}
	
		.row_doc_box_base {
	
			display: flex;
			/* position: relative; */
			float:left;
			margin-top: 10px;
			margin: 0px auto;
			width: auto;
			height: auto;
			padding: 10px;
			/* opacity: 1; */
			flex-direction: column;
			/* overflow: hidden; */
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			/* 允许换行 */
			/* justify-content: space-between; */
			/* 项目之间的间距均匀分布 */
			/* border: #00ff40 solid 1px; */
			/* overflow: scroll; */
			/* border:#976af2 solid 1px; */
		}
	
		.col_doc_box_base {
	
			display: flex;
			position: relative;
			float: left;
			margin-top: 10px;
			width: auto;
			height: 40vw;
			opacity: 1;
			flex-direction: column;
			/* overflow: hidden; */
			/* flex-wrap: wrap; */
			/* 允许换行 */
			/* justify-content: space-between; */
			/* 项目之间的间距均匀分布 */
			/* border: #00ff40 solid 1px; */
			overflow: hidden;
		}
	
		/* 第一块图文 */
		.doc3_box_1 {
	
			/*rectangle 13*/
	
			/* display: flex;
			position: relative; */
			/* margin-top: 10px; */
			/* margin-left: 5vw; */
			margin: 10px auto;
			/* left: 0px;
		top: 100px; */
			width: 100%;
			height: 40%;
			/* opacity: 1; */
			/* background: rgba(40, 25, 65, 1); */
		}
	
		.doc3_box_1_image {
			max-width: 80%;
			/* 图片最大宽度为容器的100% */
			max-height: auto;
			/* 图片最大高度为容器的100% */
			display: block;
			/* 将图片转换为块级元素 */
			margin: 0 auto;
			/* 水平居中 */
			object-fit: cover;
			overflow: hidden;
		}
	
		.doc3_box_1_text_box {
	
			opacity: 1;
			border-radius: 10px;
			background: rgba(255, 255, 255, 1);
			height: 38%;
		}
	
	
		.doc3_box_1_text {
	
	
			/*ai face swap has surged in popularity*/
	
			/* left: 83px;
		top: 2399px;
		width: 474px;
		height: 40px;
		opacity: 1; */
			/** 文本1 */
	
			font-size: 1.4em;
			font-weight: 350;
			letter-spacing: 0px;
			line-height: auto;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			/* width: 90%; */
			/* 文本最大宽度为容器的100% */
			/* display: block; */
			/* 将文本转换为块级元素 */
			/* margin: 0 auto; */
			/* 水平居中 */
			/* text-align: left; */
			/* 文字向左对齐 */
			word-wrap: break-word;
			/* 允许长单词或 URL 地址换行到下一行 */
			/* font-size: 24px; */
			/* color: #f5f2f4; */
			/* border: #ff0055 solid 1px;; */
		}
	
	
		.doc3_box_1_text2 {
	
			/** 文本1 */
			font-size: 1em;
			font-weight: 00;
			letter-spacing: 0px;
			line-height: auto;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			word-wrap: break-word;
	
		}
	
		.swap_button_style {
			width: 8em;
			height: 2em;
			opacity: 1;
			font-size: 1.3em;
			font-weight: 100;
			color: #f5f2f4;
			background: rgba(72, 52, 212, 1);
			/* border: #00ff40 solid 1px; */
			cursor: pointer;
			border-radius: 15px;
			border: none;
		}
	
		.box_center {
			display: flex;
			margin: 0 auto;
			justify-content: center;
			align-items: center;
		}
	
		/* 第3块图文 */
		.box_text_base {
	
			margin-left: 2vw;
			font-size: 1.4em;
			font-weight: 350;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(250, 247, 247);
			vertical-align: top;
			width: 90%;
			align-content: center;
			/* 文本最大宽度为容器的100% */
			/* display: block; */
			/* 将文本转换为块级元素 */
			/* margin: 0 auto; */
			/* 水平居中 */
			text-align: left;
			/* 文字向左对齐 */
			word-wrap: break-word;
	
		}
	
	
		.box_text_base2 {
	
			/** 文本1 */
			width: 100%;
			height: auto;
			margin-top: 2vw;
			margin-left: 5vw;
			font-size: 1em;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(248, 245, 245);
			vertical-align: top;
			/* word-wrap:break-word; */
			text-wrap: wrap;
			overflow-wrap: break-word; /* 允许在单词内换行 */
			word-wrap: break-word; /* 兼容旧版浏览器 */
			overflow: auto; /* 如果内容超出容器大小，显示滚动条 */
			padding: 10px;
	
		}
	
		.box_img_base {
	
			/* max-width: 50%; */
			/* 图片最大宽度为容器的100% */
			float: left;
			max-width: 90%;
			max-height: auto;
			/* 图片最大高度为容器的100% */
			display: block;
			/* 将图片转换为块级元素 */
			margin: 0 auto;
			/* 水平居中 */
			object-fit: cover;
		}
	
		.fdvfd {
			/*rectangle 14*/
	
			/* left: 1104px;
			top: 3571px; */
			/* margin-top: 7vw; */
			margin: 10px auto;
			width: 90%;
			height: auto;
			opacity: 1;
			background: rgba(217, 217, 217, 0);
			/* overflow: hidden; */
			/* object-fit:auto; */
		}
	
	
		.buy_back_box {
			/*rectangle 23*/
	
			/* left: 109px;
			top: 8298px; */
			/* display: flex;
			position: absolute; */
			width: 90%;
			height: auto;
			opacity: 1;
			border-radius: 8px;
			/* background: rgba(255, 255, 255, 1); */
			/* border: #007bff solid 1px; */
		}
	
		.buy_back_box_text {
			/*free and quaility face swaps*/
	
			/* left: 119px;
			top: 8322px; */
			/* width: 470px;
			height: 46px; */
			display: flex;
			justify-items: center;
			align-items: center;
			margin: 2px auto;
			/* margin-left: 10px; */
			width: 100%;
			height: 60px;
			text-align: center;
			/** 文本1 */
			font-size: 1.5rem;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(11, 11, 11);
			vertical-align: top;
			background-color: #f5f2f4;
			border-radius: 12px;
			/* border: #ef24c0 solid 1px; */
	
		}
	
		.buy_back_box_text2 {
			/*free and quaility face swaps*/
			margin: 2px auto;
			padding: 10px;
			/** 文本1 */
			width: 100%;
			height: auto;
			font-size: 1rem;
			font-weight: 300;
			text-wrap: wrap;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(252, 250, 250);
			vertical-align: top;
			border-radius: 12px;
			/* border: #ef24c0 solid 1px; */
			overflow: scroll;
	
		}
	
		.stars {
			/*star 1*/
			/* position: absolute; */
	
			margin: 1vw auto;
			/* border: #00ff40 solid 1px; */
			/* width: auto; */
			/* height: 30px; */
			/* display: flex; */
			text-align: center;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			/* object-fit: cover; */
		}
	
		.faq {
	
			/*1.can i face swap pnline for free*/
			display: flex;
			align-items: center;
			justify-content: left;
			margin: 5vw auto;
			width: 80%;
			height: 20px;
			opacity: 1;
			/** 文本1 */
			font-size: 20px;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: 20px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			/* border: #a694a0 solid 1px; */
		}
	
		.conect_us {
	
			display: flex;
			align-items: center;
			justify-content: left;
			/* margin: 30px auto; */
			flex-direction: row;
			color: #f5f2f4;
			letter-spacing: 2px;
			word-spacing: 2px;
			border-top: #3e3f3e solid 2px;
	
		}
	
		.connect_us_box {
			
			/* height: 500px; */
			
			display: flex;
			margin-top: 10px;
			/* margin: 10px; */
			font-size: 10px;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: 40px;
			align-items: center;
			justify-content: left;
			/* margin: 30px auto; */
			flex-direction: row;
			color: #f5f2f4;
			letter-spacing: 2px;
			word-spacing: 2px;
			/* border-top: #3e3f3e solid 2px; */
		}
}


</style>