<template>
  <div class="footer">
    <div class="footer-links">
      <!-- <router-link v-for="link in blogLinks" :key="link.path" :to="link.path"
					style="color: #f5f2f4; text-decoration: none;">
					{{ link.label }}
				</router-link> | -->
      <a :href="blogurl" target="_blank" style="color: #f5f2f4;text-decoration: none;">Blog |</a>
      <div class="footer-link">
        <a :href="termsofServiceurl" target="_blank" style="color: #f5f2f4;text-decoration: none;">Terms of
          Service |</a>
        <!-- <router-link style="color: #f5f2f4;text-decoration: none;"
						to="terms-of-service">服务条款</router-link> | -->
      </div>
      <a :href="privacypolicyurl" target="_blank" style="color: #f5f2f4;text-decoration: none;">privacy policy |</a>
      <!-- <div class="footer-link" style="margin-right: 10px;">
					<router-link style="color: #f5f2f4;text-decoration: none;" to="public/privacy-policy.html">隐私政策</router-link>
				</div> -->
      <select class="footer-link" v-model="selectedLanguage" @change="changeLanguage">
        <option v-for="lang in languages" :key="lang.value" :value="lang.value">{{ lang.label }}</option>
      </select>
    </div>
  </div>
</template>

<script setup lang='js'>
import { ref } from 'vue'
const termsofServiceurl = `${window.location.origin}/terms-of-service.html`
const privacypolicyurl = `${window.location.origin}/privacy-policy.html`
const blogurl = `${window.location.origin}/how-to-face-swap.html`

// 定义语言列表
const languages = ref([
  { value: 'en', label: 'English' },
  { value: 'zh', label: '中文' },
  { value: 'ch', label: '繁體中文' }
]);


const selectedLanguage = ref('en')

const changeLanguage = () => {
  // 这里可以添加语言切换的逻辑，比如更新页面内容或重定向到相应的语言页面
  console.log('Selected language:', selectedLanguage.value);
};
</script>

<style scoped lang="css">

select {
	margin-left: 10px;
	padding: 5px;
	border-radius: 5px;
	color: #f5f0f0;
	border: none;
	cursor: pointer;
	background-color: transparent;
}

.footer {

	color: #fff;
	padding: 20px 0;
	text-align: center;
}

.footer-links {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.footer-link {
	color: #f5f0f0;
	margin: 0 10px;
	cursor: pointer;
	transition: color 0.3s;
}

.footer-link:hover {
	color: #92b7ae;
}
</style>