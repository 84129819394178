<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
}

#app {
  /*路径*/

  left: 0px;
  top: 0px;
  width: auto;
  height: auto;
  background: url("./assets/background.png");
  overflow: hidden;

}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  background-color: #f1f1f1;
}

.login-button {
  margin-right: 0px;
} */
</style>
