import { defineStore } from 'pinia';
import { toRaw,ref } from 'vue';
import axios from 'axios';
import axiosInstance from '@/axios.config'; // 导入配置好的 Axios 实例

export const useUserStore = defineStore('user', {
  state: () => ({
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    user: JSON.parse(localStorage.getItem('user')) || null,
    userCredit: parseInt(localStorage.getItem('userCredit')) || 0,
    guestUser: JSON.parse(localStorage.getItem('guestUser')) || null,
    guestCredit: parseInt(localStorage.getItem('guestCredit')) || 0,
    showLogin: false,
    showSubcrib: false,
    mylib_result:ref([])
  }),
  actions: {

    updateMyresult(urls) { 
      this.mylib_result = urls
    },

     getters: {
        getList: (state) => state.mylib_result,
      },
    
    async fetchResultUrls() {
      try {
        const response = await axiosInstance.post('/api/swap_face/myresult', { "isLogin": this.isLoggedIn, "udid": this.user.udid });
        const data = await response.data;
        this.mylib_result = data.result_urls
        console.log("==****==", data.result_urls)
        // userStore.updateMyresult(data.result_urls);
        // console.log("====", userStore.mylib_result)
      } catch (error) {
        console.error('Error fetching results:', error);
      }
    },
    login(user, userCredits) {
      this.isLoggedIn = true;
      this.user = user;
      this.userCredit = userCredits;
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userCredit', userCredits);
      this.guestCredit = 0;
      localStorage.removeItem('guestUser');
      localStorage.removeItem('guestCredit');
      if (this.userCredit <= 0) { 
        this.showSubcrib=true
      }
    },
    logout() {
      this.isLoggedIn = false;
      this.user = null;
      this.userCredit = 0;
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      localStorage.removeItem('userCredit');
      this.guestUser = {
        name: "guest",
        uid: "",
        udid:"",
        email: "",
        displayName: "",
        avatar: "../assets/avatar.png",
        userCredits: 0
      }; // 假设非登录用户有一个默认名称
      this.guestCredit = this.userCredit;
      localStorage.setItem('guestUser', JSON.stringify(this.guestUser));
      localStorage.setItem('guestCredit', this.guestCredit);
    },
    async fetchCredits() {
      try {
        const userInfo = this.isLoggedIn ? toRaw(this.user) : toRaw(this.guestUser);
        const response = await axiosInstance.post('/api/swap_face/credit', {
          'isLogin': this.isLoggedIn,
          "userInfo": JSON.stringify(userInfo)
        });
        const credits = response.data.credit;
        if (this.isLoggedIn) {
          this.userCredit = credits;
          localStorage.setItem('userCredit', this.userCredit);
        } else {
          this.guestCredit = credits;
          localStorage.setItem('guestCredit', this.guestCredit);
          this.showSubcrib=true
        }
      } catch (error) {
        console.error('Failed to fetch credits:', error);
        throw error; // 确保错误被抛出
      }
    },

    // 非登陆用户获取或注册积分 更新 积分,更新本地值
    async updateNoneLoginUserInfo() {

      const ipAddress = await axios.get('https://ipinfo.io/?token=962fc772760b4a')
        .then(response => response.data.ip)
        .catch(() => '');
      const userAgent = navigator.userAgent;
      const deviceModel = navigator.platform || 'unknown';
      const userInfo = { ipAddress, userAgent, deviceModel };
      axiosInstance.post('/api/noneLoginCheck', {
        userInfo: userInfo,
      }).then(response => {
        // 获得消费和个人信息
        const userCredit = response.data.userCredits
        const userUdid = response.data.udid
        // 更新本地信息
        const user = {
				name: "unLogin",
				uid: userUdid,
				udid: userUdid,
				email: "",
				displayName: "",
				avatar: "",
				userCredits: userCredit
        };
        this.guestUser = user
        this.guestCredit = userCredit
        localStorage.setItem('guestUser', JSON.stringify(this.guestUser));
        localStorage.setItem('guestCredit', this.guestCredit);
		})
		.catch(error => {
      console.error('查询失败', error);
      throw error; // 确保错误被抛出
    });
    },
    
    async consumeCredits(amount) {
      
      const credits = this.isLoggedIn ? this.userCredit : this.guestCredit;
      console.log("consumeCredits",credits,amount,this.isLoggedIn,this.userCredit,this.user,)
      // console.log("consumeCredits",credits,this.isLoggedIn,this.userCredit,this.user,)
      this.showSubcrib=false
      if (credits >= amount) {
        if (this.isLoggedIn) {
          this.userCredit -= amount;
          localStorage.setItem('userCredit', this.userCredit);
        } else {
          this.guestCredit -= amount;
          localStorage.setItem('guestCredit', this.guestCredit);
        }
        try {
          const response = await axiosInstance.post('/api/swap_face/consume_points', {
            "userCredits": credits - amount,
            "udid": this.isLoggedIn ? this.user.udid : this.guestUser.udid,
            "isLogin": this.isLoggedIn,
            "consume_point":amount
          });
          const data = response.data;
          if (data.status != "success") {
            console.error('Failed to consume credits8888888:', data.message);
            if (this.isLoggedIn) {
              this.userCredit -= amount;
              localStorage.setItem('userCredit', this.userCredit);
            } else {
              this.guestCredit -= amount;
              localStorage.setItem('guestCredit', this.guestCredit);
        }
          }
        } catch (error) {
          console.error('Failed to consume credits5555:', error);
          throw error; // 确保错误被抛出
        }
      } else {
        console.error('Not enough credits');
        this.showSubcrib=true
      }
    },
  },
});

export const activeDivStore = defineStore('activeDiv', {
  state: () => ({
    activeDiv: 'swap_face',
    showMenu:false
  }),
  actions: {
    updateactiveDiv(events) {
      this.activeDiv = events;
    },
    updateMenu(events) {
      this.showMenu = events;
    },
  },
});