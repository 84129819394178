// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VueLazyload from 'vue-lazyload';
// import { useUserStore } from './store/index'
import { createPinia } from 'pinia';
import { showImageViewer, closeImageViewer,downloadImage } from './imageViewerHandler';
import VueGtag from 'vue-gtag-next';
import VueLazyload from 'vue-lazyload';
import LayerOut from '@/components/LayerOut';
import footerPage from '@/components/footerPage'


const pinia = createPinia();
const app = createApp(App);
// 全局注册 Layout 组件
app.component('LayerOut', LayerOut);
app.component('footerPage',footerPage)
app.config.globalProperties.$showImageViewer = showImageViewer;
app.config.globalProperties.$closeImageViewer = closeImageViewer;
app.config.globalProperties.$downLoadImager = downloadImage

app.use(VueGtag, {
  property: {
    id: 'G-ZSJJWR7GYK' // 替换为你的 Google Analytics 跟踪 ID
  }
}).use(VueLazyload, {
  preLoad: 1.3,
  error: new URL('./assets/error-image.png', import.meta.url).href,
  loading: new URL('./assets/loading-image.gif', import.meta.url).href,
  attempt: 3
}).use(router).use(pinia).mount('#app')
// app.use(router).use(pinia).mount('#app')
