<template>
	<!-- <span "close" @click="change_show(false)">&times;</span> -->
	<div class="popup-overlay">
		<div class="popup-content">
			<span class="close" @click="handleClose">&times;</span>
			<!-- <div @click.self="$emit('close')"> -->
			<!-- </div> -->
			<div
				style="font-size: 24px; font-weight: 800; text-align: center;border-radius: 12px;background-color:aliceblue;margin-bottom: 10px;border: none">
				Pricing Plans
			</div>
			<div class="login_box_box">
				<button v-for="(card, index) in cards" :key="index"
					:class="{ 'login_box':true, selected: selectedIndex === index }"
					@click="selectCard(index, card.credits, card.product_id, card.title, card.price)">
					<div class="singel_price_box">
						<div class="singel_price_vip" style="width: 80%;text-align: center;justify-content: center;padding: 1px;">
							{{ card.title }} {{ card.price }}
						</div>
						<div class="singel_price_vip" style="width: 100%;height: 100%;flex-direction: column;">
							<div class="singel_price_vip">
								<div>Credits</div>
								<div>
									<div>{{ card.credits }}</div>
								</div>
							</div>
							<div class="singel_price_vip">
								<div>Skip Queue</div>
								<div>
									<div>
										<img src="../assets/select.png" alt="" style="width: 1em;height: auto;">
									</div>
								</div>
							</div>
							<div class="singel_price_vip">
								<div>HD 4K </div>
								<div>
									<div>
										<img src="../assets/select.png" alt="" style="width: 1em;height: auto;">
									</div>
								</div>
							</div>
							<div class="singel_price_vip">
								<div>watermark-free</div>
								<div>
									<div>
										<img src="../assets/select.png" alt="" style="width: 1em;height: auto;">
									</div>
								</div>
							</div>
							<div class="singel_price_vip">
								<div>Ad-free</div>
								<div>
									<div>
										<img src="../assets/select.png" alt="" style="width: 1em;height: auto;">
									</div>
								</div>
							</div>
						</div>
					</div>
				</button>
			</div>
			<button class="subscribe" @click="subscribe(buyProductId)">
				Subscribe Now
			</button>
		</div>
	</div>
</template>



<script setup>

// import { ref } from 'vue'
// import { useStore } from 'vuex'
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";


/*

价格订阅逻辑

1.点击价格展示分两种 1)全新订阅 2) 额度不够购买额度 (暂时不考虑) 1) done
2.检测是否订阅是否登陆,没有登陆展示点击订阅去到登录 p0
3.价格页切换

##先构建价格表,再实现交互
*/



import { defineEmits, ref, computed } from 'vue';
// import axios from 'axios';
import { useUserStore } from '@/store/index';
import axiosInstance from '@/axios.config'; // 导入配置好的 Axios 实例
// import { loadStripe } from '@stripe/stripe-js';

// 定义 emits
// import { useStore } from 'vuex'
const userStore = useUserStore();
const emit = defineEmits(['close', 'updateScore']);
const user = computed(() => userStore.user);
const selectCredits = ref(null)
// const guestUser = computed(() => userStore.guestUser);
const userCredits = computed(() => userStore.isLoggedIn ? userStore.userCredit : userStore.guestCredit);
console.log("userCredits****", userCredits.value)
// const paymentStatus = ref('')

const cards = ref([
	// { product_id:'price_1Q56yMGcv4SJmymq0HCdpdoN',title: 'Weekly $', price:4.99 ,credits:600},
	// { product_id: 'price_1Q57KPGcv4SJmymqPbt8FQV0',title: 'Mouthly $', price: 8.99,credits:1000 },
	// { product_id: 'price_1Q57KmGcv4SJmymqwlxc2nbs',title: 'Annual $', price: 29.99,credits:4000 }
	{ product_id: 'price_1Q82rjGcv4SJmymqZ2PT8SZO', title: 'Weekly $', price: 4.99, credits: 600 },
	{ product_id: 'price_1Q53FjGcv4SJmymqwaVnJtN2', title: 'Mouthly $', price: 8.99, credits: 1000 },
	{ product_id: 'price_1Q53HJGcv4SJmymqRZmhl9oT', title: 'Annual $', price: 29.99, credits: 4000 }
]);

// const publicKey = "pk_test_51PtRvGGcv4SJmymq5OXIoUYP2hRXZhEIYGjjLte1BakRxkeoUMLANngFu4wiiEpcjFShkwVYf7jZFRJc2h76dFNt00cn2Rtyjk"
// const secrets = "sk_test_51PtRvGGcv4SJmymqu9RrowcKkBGqrmPibr22nJW3c2TlsuSpGgzT93pMOw0IEGGN8u5Nu6xqqxoYcnLtT79FRwaF00tkeFWNpE"

const selectedIndex = ref(1); // 默认选中中间的卡片
const buyProductId = ref(1)
const buyProductCredit = ref(0)
const buyProductName = ref("Mouthly")
const buyProductPrice = ref(5.99)



const selectCard = (index, money, id, product_name, product_price) => {
	selectedIndex.value = index;
	console.log(selectedIndex.value, index)
	console.log("buyCredits", money);
	selectCredits.value = money
	buyProductId.value = id
	buyProductName.value = product_name
	buyProductPrice.value = product_price
	buyProductCredit.value = money
};


const subscribe = async (productId) => {
	userStore.showLogin = false
	const product = {
		id: buyProductId.value,
		name: buyProductName.value,
		price: buyProductPrice.value,
		credit_score: buyProductCredit.value,
	};
	try {
		if (userStore.isLoggedIn != true) {
			userStore.showLogin = true
			userStore.showSubcrib = false
		}
		const response = await axiosInstance.post('/api/create-checkout-sub', {
      productId: product.id,
      productName: product.name,
			productPrice: product.price,
			productCredit: product.credit_score, // 后续通过后端查询表得到对应的积分
			userId: user.value.udid,
			userEmail: user.value.email
		});
		console.log("response", response.data.payurl)
		const payurl = response.data.payurl;
		if (payurl) {
			window.location.href = payurl;
			const orderId = response.data.orderId; // 假设响应中包含订单ID
			// console.log("orderId", orderId, defaultCredict.value)
			// checkOrderStatus(orderId);
			/** 此处是否需要回调验证订单是否支付完成??? 待核查 */
			// emit('updateScore', defaultCredict.value);
		}

	} catch (error) {
		console.error(error);
	}
};


// const checkPaymentStatus = async (session_id) => {
// 	try {
// 		const response = await axios.get(`https://127.0.0.1:4242/payment-success?session_id=${session_id}`)
// 		// todo: 请求该链接时候会更新数据库支付状态
// 		// 再次查询数据库确认是否购买成功,成功则更新积分
// 		checkOrderStatus(session_id)
// 		// paymentStatus.value = response.data

// 		// await updateCreditOnServer(defaultCredict.value); // 更新服务端信用值
// 		// emit('updateScore', defaultCredict.value);
// 	} catch (error) {
// 		console.error('Error checking payment status:', error)
// 	}
// }

// const checkOrderStatus = async (orderId) => {
// 	try {
// 		const response = await axios.get(`https://127.0.0.1:4242/swap_face/order/${orderId}`);
// 		if (response.data.status === 'paid') {
// 			// defaultCredict.value += 100; // 假设支付成功后增加100信用值
// 			// localStorage.setItem('addCredit', defaultCredict.value);
// 			await updateCreditOnServer(defaultCredict.value); // 更新服务端信用值
// 			emit('updateScore', defaultCredict.value);
// 		} else {
// 			setTimeout(() => checkOrderStatus(orderId), 2000); // 每2秒检查一次
// 		}
// 	} catch (error) {
// 		console.error('Error checking order status:', error);
// 	}
// };

// const updateCreditOnServer = async (newCredit) => {
// 	try {
// 		await axios.post('https://127.0.0.1:4242/swap_face/credit', { credit: newCredit });
// 	} catch (error) {
// 		console.error('Error updating credit on server:', error);
// 	}
// };


const handleClose = () => {
	emit('close');
};

// const urlParams = new URLSearchParams(window.location.search)
// const sessionId = urlParams.get('session_id')
// if (sessionId) {
// 	checkPaymentStatus(sessionId)
// }
// // 在页面加载时检查支付状态
// const urlParams = new URLSearchParams(window.location.search)
// const sessionId = urlParams.get('session_id')
// if (sessionId) {
// 	checkPaymentStatus(sessionId)
// }


</script>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'User_Credits'
});
</script>

<style scoped>

.login_box:hover {
	background-color: #272626;
}

.selected {
	background-color: #1e1e1e !important;
	z-index: 10000;
	/* 选中时的背景色 */
	/* border-color: #ff9900; */
}

.subscribe {
	display: flex;
	align-items: center; 
	justify-content: center; 
	width: 60%;
	height: 40px;
	font-size: 20px;
	font-weight: 400;
	margin-top: -40px;
	border-radius: 12px;
	background: linear-gradient(83deg, #d6fa30 -1%, #5ce8fa 103%);
	border: none;
}

.singel_price_box {
	display: flex; 
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	/* border: #df3030 solid 1px; */
}

.singel_price_vip {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	height: auto;
	padding: 10px;
	margin-top: 20px;
	font-size: 18px;
	font-weight: 200;
	color: #f5f2f4;
	padding: 2px;
	border-bottom: cadetblue;
	/* border: #0e1f76 solid 1px; */
}


.popup-content {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	flex-direction: column;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	position: relative;

	

}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.login_box_box {

	width: 800px;
	height: 450px;
	display: flex;
	flex-direction:row;
	margin-top: 30px;
	/* align-items: center; */
	background-color: rgba(255, 255, 255, 0.3);
	/* border: #4807ea solid 1px; */

}

.login_box {
	width: 30%;
	height: 80%;
	display: flex;
	flex-direction: column;
	margin: 0px auto;
	padding: 10px;
	/* margin-left: 15%; */
	background-color: #484848;
	align-items: center;
	border-radius: 12px;
	justify-content: space-between;
	cursor: pointer;
	/* border: none; */

}


/* 小屏幕设备 */
@media screen and (max-width: 700px) {
	.subscribe {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 60%;
			height: 40px;
			font-size: 20px;
			font-weight: 400;
			margin-top: 10px;
			border-radius: 12px;
			background: linear-gradient(83deg, #d6fa30 -1%, #5ce8fa 103%);
			border: none;
		}
	
		.singel_price_box {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 10px auto;
			width: 200px;
			height: auto;
			/* border: #df3030 solid 1px; */
		}
	
		.singel_price_vip {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 95%;
			height: auto;
			padding: 10px;
			margin-top: 20px;
			font-size: 18px;
			font-weight: 200;
			color: #f5f2f4;
			padding: 2px;
			border-bottom: cadetblue;
			/* border: #0e1f76 solid 1px; */
		}
	
	
		.popup-content {
			display: flex;
			width: 100%;
			height: auto;
			align-items: center;
			justify-content: center;
			background-color: white;
			flex-direction: column;
			padding: 20px;
			border-radius: 5px;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
			position: relative;
			overflow: scroll;
	
		}
	
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 20px;
			cursor: pointer;
		}
	
		.popup-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 1000;
			overflow: scroll;
		}
	
		.login_box_box {
	
			width: 100%;
			height: auto;
			display: flex;
			flex-direction: row;
			margin-top: 30px;
			margin: 10px auto;
			/* align-items: center; */
			background-color: rgba(255, 255, 255, 0.3);
			overflow: scroll; 
	
		}
	
		.login_box {
			width: 100%;
			height: 80%;
			display: flex;
			flex-direction: row;
			margin: 10px auto;
			padding: 10px;
			/* margin-left: 15%; */
			background-color: #484848;
			align-items: center;
			border-radius: 12px;
			justify-content: space-between;
			cursor: pointer;
			border: none;
		}
}

</style>