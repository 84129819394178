<template>
  <div>
    <meta name="description" :content="metaDescription" />
    <meta name="keywords" :content="metaKeywords" />
    <ElegantImageTextTemplate imageSrc="https://example.com/image1.jpg" imageAlt="Beautiful Landscape"
      title="Explore the Great Outdoors" description="Discover the beauty of nature with our guided tours."
      link="https://example.com/tours" linkText="View Tours" />

    <!-- <ElegantImageTextTemplate imageSrc="https://example.com/image2.jpg" imageAlt="City Skyline" title="Urban Adventures"
      description="Experience the vibrant life of the city." link="https://example.com/city-tours"
      linkText="Explore Cities" /> -->
  </div>
</template>

<script setup>
import ElegantImageTextTemplate from '@/components/ElegantImageTextTemplate.vue';

// SEO 相关的元数据
const metaDescription = "Explore the beauty of nature and vibrant cities with our guided tours.";
const metaKeywords = "nature tours, city tours, guided tours, travel, adventure";
</script>