import axios from 'axios';
const com_config = require('../config');
const baseURL = process.env.VUE_APP_API_BASE_URL || com_config.backendUrl;
const axiosInstance = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'max-age=1296000'
    // 其他自定义请求头
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // 如果请求数据是 FormData 类型，则设置请求头为 multipart/form-data
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    // 如果请求数据是文件类型，则设置请求头为 application/octet-stream
    if (config.data instanceof File) {
      config.headers['Content-Type'] = 'application/octet-stream';
    }

    // 如果请求数据是字符串类型，则设置请求头为 text/plain
    if (typeof config.data === 'string') {
      config.headers['Content-Type'] = 'text/plain';
    }
    
    return config;

  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
