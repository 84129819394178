// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
// import HomeImageList from '../views/HomeImageList.vue'
// import ImageDetail from '../views/ImageDetail.vue'
import SwapFace from '@/views/SwapFace.vue'
import ArticleList from '@/views/ArticleList.vue'
import faceAbout from '@/views/faceAbout.vue'
// import Home from '../views/Home.vue'
// import HomeWeb from '../views/HomeWeb.vue'

const routes = [
  // { path: '/list', component: HomeImageList },
  // { path: '/image/:id', component: ImageDetail },
  // { path: '/app', component: Home },
  //{ path: '/my', component: SwapFace, name: 'swap_face' },
  // { path: '/image-detail/:src', component: ImageDetail, name: 'ImageDetail', props: true },
  {
    path: '/',
    name:'face_swap',
    component: SwapFace
  },
  {
    path: '/news',
    name:'face_swap_news',
    component: ArticleList
  },
  {
    path: '/about',
    name:'face_swap_about',
    component: faceAbout
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
